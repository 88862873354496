import decodeJwt from 'jwt-decode';
import { BACKEND_URL } from '../config';

export const isAuthenticated = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return false;
  }
  return permissions === 'user' || permissions === 'admin' ? true : false;
};

export const isAdmin = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return false;
  }
  return permissions === 'admin' ? true : false;
};

export const hasAcceptedTos = () => {
  const accepted_tos = localStorage.getItem('accepted_tos');
  
  if (!accepted_tos) {
    return false;
  }
  return accepted_tos === "true" ? true : false;
};

export const accessTokenExpiringSoon = (): boolean => {
  const token = localStorage.getItem('token');

  if (token !== null) {
    const decodedToken: any = decodeJwt(token);
    const timeNow = Date.now() / 1000 + 1000;

    return decodedToken?.exp > timeNow;
  }

  return false;
};

export const decodeUserToken = (token: string) => {
  const decodedToken: any = decodeJwt(token);
  return decodedToken;
};

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${BACKEND_URL}/token`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('permissions', decodedToken.permissions);
    localStorage.setItem('accepted_tos', decodedToken?.accepted_tos);
  }

  return data;
};

/**
 * Sign up via backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }
  if (!(passwordConfirmation.length > 0)) {
    throw new Error('Password confirmation was not provided');
  }

  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(`${BACKEND_URL}/signup`, {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('permissions', decodedToken.permissions);
    localStorage.setItem('accepted_tos', decodedToken?.accepted_tos);
  }

  return data;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('accepted_tos');
};

/**
 * Send a password reset email
 *
 * @param email
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const sendPasswordResetEmail = async (email: string) => {
  // Assert email or password or password confirmation is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }

  const request = new Request(`${BACKEND_URL}/password-recovery/${email}`, {
    method: 'POST',
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  return data;
};

/**
 * resetUserPassword
 *
 * @param token
 * @param password
 * @param passwordConfirmation
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const resetUserPassword = async (
  token: string,
  password: string,
  passwordConfirmation: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(token.length > 0)) {
    throw new Error('Token was not provided');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }
  if (!(passwordConfirmation.length > 0)) {
    throw new Error('Password confirmation was not provided');
  }

  const body = {
    new_password: password,
    token: token,
  };

  const request = new Request(`${BACKEND_URL}/reset-password/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  return data;
};

/**
 * Using the current token refresh the one stored one
 *
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const refreshToken = async () => {
  const token = localStorage.getItem('token');

  // Assert email or password is not empty
  if (token === null) {
    throw new Error('Could not refresh token - no token found');
  }

  const request = new Request(`${BACKEND_URL}/token/refresh`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  // try {
  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  if (response.status === 401) {
    throw new Error('Could not refresh token not authorized');
  }

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('permissions', decodedToken.permissions);
    localStorage.setItem('accepted_tos', decodedToken?.accepted_tos);
  }

  return data;
  // } catch (error) {
  //   console.log('refreshTokenError', error);
  // }
};
