import React, { FC } from 'react';


import {
  Box,
  Link,
  Grid,
  GridItem,
  Container
} from '@chakra-ui/react';

export interface FooterProps {
  autheticated: boolean;
}

export const Footer: FC<FooterProps> = ({ autheticated }) => {
  return (
    <Box as="footer"
    borderTop="1px"
    borderColor="gray.200"
    bg="gray.50"
    pt="4"
    pb={4}
    >
      <Container maxW="container.xl">

      
      <Grid
        // h='200px'
        // templateRows='repeat(2, 1fr)'
        templateColumns='repeat(3, 1fr)'
        gap={4}
      
      >
        <GridItem ><Link href="./digital4grids_data_protection_policy.pdf">Data Privacy Policy</Link></GridItem>
        <GridItem><Link href="https://www.digital4grids.com/#contact" isExternal>Contact</Link></GridItem>
        
      </Grid>
      </Container>
    </Box>
  )
}
