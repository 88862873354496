import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getAllUsers } from '../../utils';
import { FormInputs } from './types';

interface UserItem {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

const DeviceInfoForm = ({
  onSubmit,
  initialData = {},
}: {
  initialData?: {};
  onSubmit: (data: any) => void
}) => {
  console.log('FORM DEFAUL INIT VALS = ', initialData)
  const [users, setUsers] = useState<UserItem[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: initialData });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const user_response = await getAllUsers();
        setUsers(user_response);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
  

  
  // const onSubmit = (data) => {
  //   // Handle form submission, either create or update device_info
  //   console.log(data);
  //   handleSubmit(data)
  //   // Replace with your API call to create or update device_info
  // };

  return (
    <Box p={4}>
      {users.length && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <input type="text" {...register('device_id', { required: true })} placeholder="Device ID" /> */}
          <FormControl isInvalid={errors.device_id ? true : false}>
            <FormLabel htmlFor="device_id">Device ID</FormLabel>
            <Input
              id="device_id"
              {...register('device_id', { required: true })}
              placeholder="Device ID"
            />
            <FormErrorMessage>Device ID is required</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="device_type">Device Type</FormLabel>
            <Select id="device_type" {...register('device_type')}>
              <option value="">Select type</option>
              <option value="spoony">Spoony</option>
              <option value="netatmo">Netatmo</option>
              <option value="shelly">Shelly</option>
              <option value="linky">Linky</option>
              <option value="dcbel">DCBel</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="phase_m">Device Measure Kind</FormLabel>
            <Select id="phase_m" {...register('phase_m')}>
              <option value="">Select kind</option>
              <option value="single">Single</option>
              <option value="three">Three</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="owner_id">Select Owner</FormLabel>
            <Select id="owner_id" {...register('owner_id')}>
              <option value="">Select Owner</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.id} {user.email}
                </option> // Assuming user has an id and name property
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="location_id">Location ID</FormLabel>
            <Input
              id="location_id"
              {...register('location_id', { required: false })}
              placeholder="Location ID"
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Submit
          </Button>
        </form>
      )}
    </Box>
  );
};

export default DeviceInfoForm;
