import React, { FC, useState, useEffect } from 'react';
import {
  getLegrandLink,
  getLinkyConnectLink,
  getNetatmoLink,
} from '../utils/api';
import { BACKEND_URL, BACKEND_URL_NAKED } from '../config';
import {
  Heading,
  Container,
  Button as ChakraButton,
  Box,
  HStack,
  Text,
  Grid,
  SimpleGrid,
  OrderedList,
  ListItem,
  UnorderedList,
  VStack,
  Link,
  Divider,
} from '@chakra-ui/react';

export const LegrandSetup: FC = () => {
  const [legrandLink, setLegrandLink] = useState<string>('');
  const [netamoLink, setNetamoLink] = useState<string>('');
  const [linkyLink, setLinkyLink] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [errorLinky, setLinkyError] = useState<string>('');

  const queryBackend = async () => {
    try {
      // const result = await getLegrandLink();
      // setLegrandLink(result);
    } catch (err) {
      setError(err);
    }
  };

  const queryNetamoLink = async () => {
    try {
      const result = await getNetatmoLink();
      setNetamoLink(result);
    } catch (err) {
      setError(err);
    }
  };

  const redirectToLinky = async () => {
    try {
      const result = await getLinkyConnectLink();
      setLinkyLink(result);
    } catch (err) {
      setLinkyError(err);
    }
  };

  useEffect(() => {
    queryBackend();
    queryNetamoLink();
    redirectToLinky();
  }, []);

  return (
    <>
      <Container maxW={'7xl'} p="12">
        <Heading as="h1">Your Integrations</Heading>
        <SimpleGrid
          // minChildWidth="120px"
          spacing={6}
          columns={{ base: 1, md: 2 }}
        >
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
            <Heading fontSize="xl">Legrand</Heading>
            <Text>
              By using this service we will be able to access the power
              consumption data and status of your Legrand IoT products in your
              home.
            </Text>

            <Text>
              Data will then be presented on your grafana dashboard alongside
              your spoony data
            </Text>
            <br />
            {legrandLink && !error && (
              <>
                <ChakraButton
                  as="a"
                  href={legrandLink}
                  colorScheme="teal"
                  size="md"
                >
                  Connect to legrand
                </ChakraButton>
              </>
            )}
            <Box py={5}>
              <Heading fontSize="xl">Netatmo</Heading>
              <Text>
                Netatmo is the replacement for the legrand home and control
                service which is used above as it will be depreciated in 2023.
              </Text>
              <br />
              <Text>
                To continue collecting data from your legrand devices we need
                you to connect to this service.
              </Text>
              <br />
              <Text>
                By connecting D4Grids to Netatmo we will be able to collect
                state information on your smart meters, smart plugs and
                thermostat.
              </Text>
              <br />
              <Text>
                In future it will be possible for us to automatically turn some
                of your devices on and off.
              </Text>
              <br />
              {netamoLink && !error && (
                <>
                  <ChakraButton
                    as="a"
                    href={netamoLink}
                    colorScheme="orange"
                    size="md"
                  >
                    Connect to netatmo
                  </ChakraButton>
                </>
              )}
            </Box>
          </Box>
          <Box p={5} shadow="md" borderWidth="1px" flex="1" borderRadius="md">
            <VStack spacing="2" align="start">
              <Heading fontSize="xl">Linky Connection</Heading>
              <Text as="p">
                By using this service we will access your power consumption data
                for your Linky meter and use it to compare with the other
                sensors in your home
              </Text>
              <Text fontWeight="bold">To do this you will need</Text>
              <Box pl={1}>
                <UnorderedList>
                  <ListItem>A Linky meter</ListItem>
                  <ListItem>An Enedis personal space</ListItem>
                  <ListItem>
                    Tokens which will be created by this tool and used to access
                    the data on your behalf
                  </ListItem>
                </UnorderedList>
              </Box>
              <Text as="p">
                On a periodic basis we will read data from the Enedis API which
                allows us to read your consumption data for the meters you have
                given us permission to read from.
              </Text>
              <Text>
                We will store this data in our database for the purposes of
                visualisng your data alongside the other sensors in your home
                for which you have given us permission to read from (e.g.
                Legrand, Spoony)
              </Text>
              <Text>
                We will pull the following data from your meter - 30min + daily
                consumption and 30min + daily production
              </Text>
              <Text>
                You remain in control of your data, at any time you may request
                to discontinue this service and we will delete all your stored
                data.
              </Text>
              {linkyLink && !error && (
                <Link href={linkyLink}>
                  <img src={'./connect-enedis-logo.png'} />
                </Link>
              )}
            </VStack>
          </Box>
        </SimpleGrid>
      </Container>
    </>
  );
};
