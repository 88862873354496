import React, { FC, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';

import useAuth from '../hooks/useAuth';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Checkbox as ChakraCheckbox,
  Flex,
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

import { ButtonLink } from '../components/ButtonLink';

import { EmailIcon, LockIcon } from '@chakra-ui/icons';

export const Login: FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');

  const { login, user } = useAuth();

  const handleSubmit = async (_: React.MouseEvent) => {
    _.preventDefault();
    setError('');
    try {
      // const data = await login(email, password);
      const data = await login(email, password);
      console.log('Called login from the component and heres the data', data);
      if (data) {
        history.push('/');
      }
    } catch (err) {
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(err);
      }
    }
  };

  return user ? (
    <Redirect to="/" />
  ) : (
    <Flex align={'center'} justify={'center'} my={20}>
      <Box boxShadow="base" p="6" w="md">
        {error && (
          <ChakraAlert status="error" variant="left-accent">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription display="block">{error}</AlertDescription>
            </Box>
          </ChakraAlert>
        )}
        <form>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon color="gray.300" />}
                />
                <Input
                  type="email"
                  placeholder="Email *"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.currentTarget.value)
                  }
                />
              </InputGroup>
            </FormControl>
            <FormControl id="password" isRequired>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<LockIcon color="gray.300" />}
                />
                <Input
                  type="password"
                  placeholder="Password *"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.currentTarget.value)
                  }
                />
              </InputGroup>
            </FormControl>
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              align={'start'}
              justify={'space-between'}
              alignItems={'center'}
            >
              <ChakraCheckbox>Remember me</ChakraCheckbox>
              {/* <ChakraButton variant="ghost">Forgot password?</ChakraButton> */}
              <ButtonLink
                href="/forgot-password"
                variant="ghost"
                title="Forgot password?"
              />
              
            </Stack>
            <ChakraButton
              variant="outline"
              colorScheme="green"
              onClick={handleSubmit}
              type="submit"
            >
              Login
            </ChakraButton>
            <ButtonLink
              href="/signup"
              title="Sign Up"
              variant="outline"
              w={'full'}
            />
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};
