import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
} from '@chakra-ui/react';

import { createDeviceInfo, getAllUsers, getDeviceInfo, getProfile, updateDeviceInfo, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { useForm } from 'react-hook-form';
import { FormInputs } from './types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { sanitizeEmptyValues } from '../../utils/forms';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

interface FormEditInputs extends FormInputs {
  id: string;

}

export const EditDeviceInfo: FC = () => {
  const history = useHistory();
  const toast = useToast();
  let params = useParams<{deviceId: string}>();
  console.log('ROUTE PARAMS', params)
  const deviceId = '123'
  
  const [device, setDevice] = useState<FormEditInputs>()
  
  const [profile, setProfile] = useState<ProfileData>({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const handleChange = (fieldId: keyof ProfileData, value: any) => {
    // setProfile((currentProfile) => {
    //   const newProfile = { ...currentProfile };
    //   newProfile[fieldId] = value;

    //   return newProfile;
    // });
  };

  const queryBackend = async (deviceId) => {
    try {
      const response = await getDeviceInfo(deviceId)
      setDevice(response)

    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend(params.deviceId);
  }, []);

  const { handleSubmit } = useForm<FormInputs>();

  const onSubmit = async (data) => {
    console.log('EDIT SUBMIT', data);
    const newData = {...data, id: params.deviceId}
   
    // Your form submission logic here
    const response = await updateDeviceInfo(sanitizeEmptyValues(newData), params.deviceId)
    console.log(response)
    history.push(`/device-info/${params.deviceId}`);
  };

  return (
    <>
      <Heading>Edit Device Info page</Heading>
      
      <ButtonLink href="/device-info" title="back to list" />
      {device && device.id && <DeviceInfoForm onSubmit={onSubmit} initialData={device} />}
    </>
  );
};
