import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
} from '@chakra-ui/react';

import { getProfile, updateMyAccount } from '../utils/api';
import useAuth from '../hooks/useAuth';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

export const Protected: FC = () => {
  const toast = useToast();
  const [profile, setProfile] = useState<ProfileData>({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const handleChange = (fieldId: keyof ProfileData, value: any) => {
    setProfile((currentProfile) => {
      const newProfile = { ...currentProfile };
      newProfile[fieldId] = value;

      return newProfile;
    });
  };

  const queryBackend = async () => {
    try {
      const profile = await getProfile();
      setProfile(profile);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);

  const handleSubmit = async (_: React.MouseEvent) => {
    setError('');
    try {
      const { first_name, last_name } = profile;
      const inputData = { first_name, last_name, email: user.email };
      const data = await updateMyAccount(user.id, inputData);

      if (data) {
        toast({
          position: 'top',
          isClosable: true,
          status: 'success',
          title: 'Success',
          description: 'Your profile was updated ✅',
        });
        queryBackend();
      }
    } catch (err) {
      console.log('Ruho error');
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(err);
      }
    }
  };

  return (
    <>
      {profile.email === '' && <>Loading...</>}
      {profile.email !== '' && (
        <Box boxShadow="base" p="6">
          <Heading as="h2">My Account</Heading>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input
              type="text"
              value={profile.email}
              // onChange={(e) => handleChange('email', e.target.value)}
              disabled
            />
          </FormControl>

          <FormControl id="first_name">
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={profile.first_name || ''}
              onChange={(e) => handleChange('first_name', e.target.value)}
            />
          </FormControl>

          <FormControl id="last_name">
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={profile.last_name || ''}
              onChange={(e) => handleChange('last_name', e.target.value)}
            />
          </FormControl>

          <ChakraButton
            type="submit"
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            onClick={handleSubmit}
          >
            Save
          </ChakraButton>

          {/* <TextField
              id="first_name"
              label="First Name"
              type="first_name"
              value={profile?.first_name || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                console.log(e.currentTarget.value)
              }
              fullWidth
            />
            <TextField
              id="last_name"
              label="Last Name"
              type="last_name"
              value={profile?.last_name || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                console.log(e.currentTarget.value)
              }
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              // onClick={handleSubmit}
            >
              Save
            </Button> */}
        </Box>
      )}
    </>
  );
};
