/**
 * Remove empty strings from form state
 *
 * @example
 * sanitizeEmptyValues({ foo: '', bar: 'baz' }) // { bar: 'baz' }
 */
export const sanitizeEmptyValues = (values: any, record: any = {}): any => {
    const sanitizedValues ={};
    Object.keys(values).forEach(key => {
      
      // if (typeof values[key] === 'object' && Object.keys(values[key]).length > 0 && Array.isArray(values[key])=== false) {
      //   console.log('Running through object', values[key])
      //   sanitizedValues[key] = sanitizeEmptyValues(values[key])
      // }
      if (values[key] && Array.isArray(values[key]) === true) {
        console.log('Running through array', key)
        sanitizedValues[key] = values[key].map(v => (v && typeof v === 'object') ? sanitizeEmptyValues(v) : v)
        return
      }

      if (values[key] && typeof values[key] === 'object') {
        console.log('Running through object', key)
        sanitizedValues[key] = sanitizeEmptyValues(values[key])
        if (Object.keys(sanitizedValues[key]).length === 0) {
          sanitizedValues[key] = null;
        }
        return
      }
      
        // console.log('Checking', key, values[key], values[key] == null || values[key] === '')
        if (values[key] == null || values[key] === '') {
            

            if (record.hasOwnProperty(key)) {
                // user has emptied a field, make the value null
                sanitizedValues[key] = null;
            } 
            else {
                // user has emptied a field, but the initial value was undefined
                // so we don't include it in the sanitized values
            }
        } else {
            // this is a non-empty value, so we include it in the sanitized values
            sanitizedValues[key] = values[key];
        }
    });

    console.log('InternalSanitised', values, sanitizedValues)
    return sanitizedValues;
};
