import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
  Grid,
  GridItem,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Button
} from '@chakra-ui/react';

import { createDeviceInfo, createDeviceMetadataInfo, getAllUsers, getDeviceInfo, getProfile, updateDeviceMetadataInfo, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { useForm } from 'react-hook-form';
import { FormInputs } from './types';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import DeviceMetadataForm from './MetadataForm';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

interface DeviceMetedata {
  name: string;
  display_name: string;
  measure_type: string;
  device_id: number;
  id: number;
  created_at: Date;
  updated_at: Date;
}

interface FormEditInputs extends FormInputs {
  id: string;
  metadata: DeviceMetedata[];

}

export const ViewDeviceInfo: FC = () => {
  const history = useHistory();
  const toast = useToast();
  let params = useParams<{deviceId: string}>();
  console.log('ROUTE PARAMS', params)
  const deviceId = '123'
  
  const [device, setDevice] = useState<FormEditInputs| null>(null);
  
  const [profile, setProfile] = useState<ProfileData>({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const [isEditingMetadata, setIsEditingMetadata] = useState(false);
  const [metadataToEdit, setMetadataToEdit] = useState(null);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const canAddMultipleMeasures = device?.device_type && ["spoony", "shelly"].includes(device?.device_type) && device?.phase_m === "single";

  const { user } = useAuth();

  const handleChange = (fieldId: keyof ProfileData, value: any) => {
    // setProfile((currentProfile) => {
    //   const newProfile = { ...currentProfile };
    //   newProfile[fieldId] = value;

    //   return newProfile;
    // });
    
  };
  const queryBackend = async (deviceId) => {
    try {
      const response = await getDeviceInfo(deviceId)
      setDevice(response)

    } catch (err) {
      setError(err);
    }
  };

  const updateDeviceMeta = async (deviceMetaId, data) => {
    try {
      const response = await updateDeviceMetadataInfo(data, deviceMetaId)
      await queryBackend(params.deviceId)

    } catch (err) {
      setError(err);
    }
  };

  const createDeviceMeta = async (data) => {
    try {
      const response = await createDeviceMetadataInfo(data)
      await queryBackend(params.deviceId)

    } catch (err) {
      setError(err);
    }
  };

  

  useEffect(() => {
    queryBackend(params.deviceId);
  }, []);

  const { handleSubmit } = useForm<FormInputs>();

  const onSubmit = async (data) => {
    console.log('EDIT SUBMIT', data);
    // Your form submission logic here
    // const response = await createDeviceInfo(data)
    // console.log(response)
    // history.push('/device-info');
    if (isCreatingNew === true) {
        await createDeviceMeta(data)
    } else {
      await updateDeviceMeta(data.id, data)
      setMetadataToEdit(null);
    }

    onClose();
    setIsCreatingNew(false);
  };

  const handleEditMetadata = (metadata) => {
    setMetadataToEdit(metadata);
    onOpen();
  };

  const handleNewMeasure = () => {
    setIsCreatingNew(true);
    onOpen();
  };

  if (!device) {
    return <div>Loading...</div>
  }

  const isSinglePhaseSpoony =  ["spoony", "shelly"].includes(device.device_type) && device.phase_m === "single"
  const enableNewButton = (device.metadata.length < 3 && isSinglePhaseSpoony) || isSinglePhaseSpoony === false && device.metadata.length < 1
  
  console.log({isSinglePhaseSpoony, enableNewButton, checkLen1: isSinglePhaseSpoony === false && device.metadata.length < 1})
  const defaultName = isSinglePhaseSpoony === false ? "ABC" : `${device.device_type}.updatename`
  const defaultDisplayName = isSinglePhaseSpoony === false ? "Phase ABC" : `insert name`
  return (
    <>     
      <Heading as="h2" mb={4}>Device Information</Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem>
          <Flex direction="column">
            <Text fontWeight="bold">Device ID:</Text>
            <Text>{device.device_id}</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex direction="column">
            <Text fontWeight="bold">Device Type:</Text>
            <Text>{device.device_type}</Text>
          </Flex>
        </GridItem>

        <GridItem>
          <Flex direction="column">
            <Text fontWeight="bold">Owner Name:</Text>
            <Text>{device.owner_id}</Text>
          </Flex>
        </GridItem>
        <GridItem>
          <Flex direction="column">
            <Text fontWeight="bold">Location ID:</Text>
            <Text>{device.location_id}</Text>
          </Flex>
        </GridItem>
      </Grid>
      <Heading as="h3" mb={4} size={'md'}>Device Measurements</Heading>
      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
        
        {device.metadata.map((metadata) => (
          <GridItem key={metadata.id}>
            <Box borderWidth="1px" borderRadius="md" p={2}>
              <Text fontWeight="bold">{metadata.name}</Text>
              <Text fontWeight="bold">Display Name:</Text>
              <Text>{metadata.display_name}</Text>
              <Text fontWeight="bold">Type:</Text>
              <Text>{metadata.measure_type}</Text>
              <Button onClick={() => handleEditMetadata(metadata)}>Edit</Button>
            </Box>
          </GridItem>
        ))}
      </Grid>
      <Button onClick={handleNewMeasure} disabled={!enableNewButton}>New Measure</Button>
      <ButtonLink href={`/device-info/`} title="back to list" /> |  <ButtonLink href={`/device-info/${params.deviceId}/edit`} title="Edit Device Info" />
      
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isCreatingNew ? 'New Measure' : 'Update Measure'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Edit metadata form */}
            <DeviceMetadataForm create={isCreatingNew} initialData={isCreatingNew ? {name: defaultName, display_name: defaultDisplayName, device_id: params.deviceId} : metadataToEdit ?? {}} onSubmit={onSubmit} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
