import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  accessTokenExpiringSoon,
  isAuthenticated,
  login as Login,
  logout as Logout,
  refreshToken,
} from '../utils/auth';

import { getProfile } from '../utils/api';
// Probably should move these elsewhere

export interface User {
  email: string;
  name: string;
  first_name?: string | null;
  last_name?: string | null;
  id: number;
  is_active: boolean;
  is_superuser: boolean;
}

export interface AuthContextType {
  user: User;
  loading: boolean;
  error?: any;
  login: (email: string, password: string) => any;
  // signUp: (email: string, name: string, password: string, passwordConfirmation: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<Response | string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();

  async function refresh(): Promise<void> {
    try {
      const res = await refreshToken();
      console.log('refreshed token with', res);
    } catch (error: Error | unknown) {
      console.log('refreshed token error', error);
      setError((error as Error).message);
    }
  }

  useEffect(() => {
    if (error) setError(null);
    console.log('Changed page and....still auth?', isAuthenticated());

    if (isAuthenticated() === true && accessTokenExpiringSoon()) {
      refresh();
    }

    if (user) {
      getProfile()
        .catch((_error) => {
          console.log('hitting the error block');
          console.log('Path change profile error', _error)
          if (_error instanceof Error) {
            const {name, message} = _error
            console.log(name, message)
            if (message === 'Failed to authenticate user profile - 401') {
              Logout();
              setUser(undefined);
              history.push('/login');
            }
          }
        })
        .finally(() => setLoadingInitial(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // send to login page on load if no user
  useEffect(() => {
    getProfile()
      .then((newUser) => {
        if (!newUser.email) {
          setUser(undefined);
          throw 'User not logged in';
        } else {
          setUser(newUser);
        }
      })
      .catch((_error) => {
        console.log('hitting the error block');
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  async function login(email: string, password: string) {
    setLoading(true);

    await Login(email, password)
      .then((newUser) => {
        console.log('called the login action, going to update state', newUser);
        getProfile()
        .then((newUser) => {
          if (!newUser.email) {
            setUser(undefined);
            throw 'User not logged in';
          } else {
            setUser(newUser);
            history.push('/');
          }
        })
        .catch((_error) => {
          console.log('hitting the error block');
        })
        // setUser({ email: 'test email', name: 'Colin Test' });
        // history.push('/');
      })
      .catch((newError) => {
        setError(newError);
        throw newError;
      })
      .finally(() => setLoading(false));
  }

  function logout() {
    Logout();
    setUser(undefined);
    history.push('/');
  }

  // Make the provider update only when it should
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      // signUp,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error]
  );

  useEffect(() => {
    console.log('[ERROR CHANGED]', error)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <AuthContext.Provider value={memoedValue as AuthContextType}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
