import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
} from '@chakra-ui/react';

import { createDeviceInfo, createLocation, getAllUsers, getDeviceInfo, getLocationById, getProfile, updateDeviceInfo, updateLocation, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { sanitizeEmptyValues } from '../../utils/forms';
import LocationForm from './Form';

interface LocationItem {
  name: string;
  lat: number;
  user_id: number;
  id: string;
  country: string;
  lon: number;
}

export const NewLocation: FC = () => {
  const history = useHistory();
  const toast = useToast();
  let params = useParams<{locationId: string}>();
  
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const { handleSubmit } = useForm<LocationItem>();

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      lat: parseFloat(data.lat),
      lon: parseFloat(data.lon)
    }
   
    // Your form submission logic here
    const response = await createLocation(sanitizeEmptyValues(newData))
    console.log(newData)
    history.push(`/locations/`);
  };

  return (
    <>
      <Heading>Add Location</Heading>
      
      <ButtonLink href="/locations" title="back to list" />
      <LocationForm onSubmit={onSubmit} initialData={{}} />
    </>
  );
};
