import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { isAuthenticated } from '../utils/auth';

import useAuth from "../hooks/useAuth";

type PrivateRouteType = {
  component: React.ComponentType<any>;
  path?: string | string[];
} & RouteProps;



export const PrivateRoute: FC<PrivateRouteType> = ({
  component,
  ...rest
}: any) => {
  const { user } = useAuth();
  if (!user) return <Redirect to="/login" />

  return <Route
    {...rest}
    render={(props: any) => !user ? <Redirect to="/login" /> : React.createElement(component, props) }
   />
}
