import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, useToast, Text, Link, ListItem, ListIcon, List, Button } from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';
import { IntegrationsList } from '../components/IntegrationsList';
import { LegrandPlants } from '../components/LegrandPlants';
import { LinkyPlants } from '../components/LinkyPlants';
import { NetatmoHomes } from '../components/NetatmoHomes';
import { hasAcceptedTos } from '../utils';

export const Integrations: FC = () => {
  const [acceptedTos, setAcceptedTos] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const created = params.get('created');
    const updated = params.get('updated');
    if (created) {
      toast({
        position: 'top',
        isClosable: true,
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Your integration has been created
          </Box>
        ),
      });
    }
    if (updated) {
      toast({
        position: 'top',
        isClosable: true,
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Your integration has been updated
          </Box>
        ),
      });
    }
    console.log('[HOME] Found these params on the page', params);
  }, []);

  useEffect(() => {
    const hasAcceptedTosVal = hasAcceptedTos();
    console.log('USE EFFECT GETTING TOS', hasAcceptedTosVal);
    setAcceptedTos(hasAcceptedTosVal);
  }, []);

  return (
    <Container maxW="container.xl">
          {acceptedTos === false && (
            <Box
              marginTop={4}
              marginBottom={4}
              borderBottom="1px"
              borderColor="gray.200"
              padding={4}
              bg="gray.50"
              borderRadius="lg"
            >
              <Heading as="h3" size="md">
                Digital4Grids Privacy Principles
              </Heading>
              <Text>
                Legitimate data access for energy, power & carbon footprint
                monitoring, flexibility baseline and offering calculations and
                energy price benchmarks.
              </Text>

              <List spacing={3} marginTop={2}>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Alignment with European Global Data Protection Regulation
                  principles:{' '}
                  <Link
                    color="teal.500"
                    href="https://gdpr-info.eu/"
                    target="_blank"
                  >
                    <Text as="u">gdpr-info.eu</Text>
                  </Link>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Possibility to export data history upon request using flat
                  .csv or standardized IEC 62746-4 based formats.
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  If you wish to cancel D4G services, they can request
                  cancellation of their data history by email to{' '}
                  <Text as="b">aline.levaillant@digital4grids.com</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Possibility to share real-time data through the cloud with
                  explicit consent management : as part of european{' '}
                  <Text as="b">OneNet</Text> project, associated data are
                  expected to be shared with Elering and its relevant IT
                  partners.
                </ListItem>
              </List>
              <Text marginTop={2}>
                Download the{' '}
                <Link
                  color="teal.500"
                  href="./digital4grids_data_protection_policy.pdf"
                  isExternal
                >
                  Data Privacy Policy
                </Link>
              </Text>

              <Button
                onClick={(e) => console.log(e)}
                marginTop={2}
                leftIcon={<CheckIcon />}
                colorScheme="blue"
                variant="solid"
                size="sm"
              >
                Confirm Acceptance
              </Button>
            </Box>
          )}

          <Box
            marginBottom={4}
            borderBottom="1px"
            borderColor="gray.200"
            paddingBottom={4}
            paddingTop={5}
          >
            <Heading>My Dashboard</Heading>
            <Text>
              Welcome to your digital4grids dashboard. Here you will find
              details of what integrations you've enabled and when they were
              last updated. For some you will also be able to request data from
              the external data source to sample the kind of data we are using
              on your behalf in our dashboards.
            </Text>
          </Box>

          <IntegrationsList />
          <LegrandPlants />
          <LinkyPlants />
          <NetatmoHomes />
        </Container>
  );
};
