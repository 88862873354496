import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
} from '@chakra-ui/react';

import { createDeviceInfo, getAllUsers, getDeviceInfo, getLocationById, getProfile, updateDeviceInfo, updateLocation, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { sanitizeEmptyValues } from '../../utils/forms';
import LocationForm from './Form';

interface LocationItem {
  name: string;
  lat: number;
  user_id: number;
  id: string;
  country: string;
  lon: number;
}

export const EditLocation: FC = () => {
  const history = useHistory();
  const toast = useToast();
  let params = useParams<{locationId: string}>();
  console.log('ROUTE PARAMS', params)
  
  const [location, setDevice] = useState<LocationItem>()
  
  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const queryBackend = async (locationId) => {
    try {
      const response = await getLocationById(locationId)
      setDevice(response)

    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend(params.locationId);
  }, []);

  const { handleSubmit } = useForm<LocationItem>();

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      lat: parseFloat(data.lat),
      lon: parseFloat(data.lon)
    }
   
    // Your form submission logic here
    const response = await updateLocation(sanitizeEmptyValues(newData), params.locationId)
    console.log(newData)
    history.push(`/locations/`);
  };

  return (
    <>
      <Heading>Edit Location</Heading>
      
      <ButtonLink href="/locations" title="back to list" />
      {location && location.id && <LocationForm onSubmit={onSubmit} initialData={location} />}
    </>
  );
};
