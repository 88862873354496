import React, { FC, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import SvgDigital4Grids from '../icons/digital4grids';

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';

import { isAdmin } from '../../utils/auth';
import useAuth from '../../hooks/useAuth';

const headersDataLoggedIn = [
  {
    label: 'Home',
    href: '/',
    permissions: null,
  },
  {
    label: 'Integrations',
    href: '/integrations',
    permissions: null,
  },
  // {
  //   label: 'Integration Setup',
  //   href: '/integrations-setup',
  //   permissions: null,
  // },
  // {
  //   label: 'Schedules',
  //   href: '/schedules',
  //   permissions: 'admin',
  // },
  // {
  //   label: 'Dashboards',
  //   href: '/dashboards',
  //   permissions: null,
  // },
  {
    label: 'My Account',
    href: '/protected',
    permissions: null,
  },
  {
    label: 'Admin',
    href: '/admin',
    permissions: 'admin',
  },
  {
    label: 'Devices',
    href: '/device-info',
    permissions: null,
  },
  {
    label: 'Locations',
    href: '/locations',
    permissions: null,
  },
  // {
  //   label: 'Devices',
  //   href: '/user-devices',
  //   permissions: 'admin',
  // },
  {
    label: 'Log Out',
    href: '/logout',
    permissions: null,
  },
];

const headersNotLoggedIn = [
  {
    label: 'Home',
    href: '/',
    permissions: null,
  },
  {
    label: 'Log in',
    href: '/login',
    permissions: null,
  },
];

export interface HeaderProps {
  autheticated: boolean;
}

const NavLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    to={href}
    as={RouterLink}
  >
    {children}
  </Link>
);

export const Header: FC<HeaderProps> = ({ autheticated }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // let NavLinks = isAuthenticated() ? headersDataLoggedIn : headersNotLoggedIn;

  const { user } = useAuth();

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Flex alignItems={'center'}>
              {/* <Text fontWeight="black">Digital4Grids Integration Demo</Text> */}
              {/* <Image src="./d4grids-logo-no-globe.svg" boxSize='150px'/> */}
              <SvgDigital4Grids height={'3rem'} />
            </Flex>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
            >
              {user &&
                headersDataLoggedIn.map((link) => {
                  if (
                    link.permissions &&
                    link.permissions === 'admin' &&
                    !isAdmin()
                  )
                    return null;
                  return (
                    <NavLink key={link.label} href={link.href}>
                      {link.label}
                    </NavLink>
                  );
                })}

              {!user &&
                headersNotLoggedIn.map((link) => (
                  <NavLink key={link.label} href={link.href}>
                    {link.label}
                  </NavLink>
                ))}
            </HStack>
          </HStack>
          {user && (
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                >
                  <Avatar
                    size={'sm'}
                    name={
                      user?.first_name === null
                        ? user.email
                        : `${user.first_name} ${user.last_name}`
                    }
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem to={`/protected`} as={RouterLink}>
                    My Account
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem as={RouterLink} to={'/logout'}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          )}
          {!user && <Box></Box>}
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {user &&
                headersDataLoggedIn.map((link) => (
                  <NavLink key={link.label} href={link.href}>
                    {link.label}
                  </NavLink>
                ))}

              {!user &&
                headersNotLoggedIn.map((link) => (
                  <NavLink key={link.label} href={link.href}>
                    {link.label}
                  </NavLink>
                ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

// export const Header1: FC<HeaderProps> = ({autheticated}) => {

//   const [state, setState] = useState({
//     mobileView: false,
//     drawerOpen: false,
//   });

//   const { mobileView, drawerOpen } = state;

//   useEffect(() => {
//     const setResponsiveness = () => {
//       return window.innerWidth < 900
//         ? setState((prevState) => ({ ...prevState, mobileView: true }))
//         : setState((prevState) => ({ ...prevState, mobileView: false }));
//     };

//     setResponsiveness();
//     window.addEventListener("resize", () => setResponsiveness());

//     return () => {
//       window.removeEventListener("resize", () => setResponsiveness());
//     }
//   }, []);

//   const d4gridsDemoLogo = (
//     <Typography variant="h6" component="h1">
//       Digital4Grids Integration Demo
//     </Typography>
//   );

//   const getMenuButtons = () => {
//     const headersData = isAuthenticated() ? headersDataLoggedIn : headersNotLoggedIn;

//     return headersData.map(({ label, href, permissions }) => {
//       if (permissions && permissions === 'admin' && !isAdmin()) return null
//       return (
//           <Button
//             {...{
//               key: label,
//               color: "inherit",
//               to: href,
//               component: RouterLink,
//             }}
//           >
//             {label}
//           </Button>
//         );
//     });
//   };

//   const getDrawerChoices = () => {
//     const headersData = isAuthenticated() ? headersDataLoggedIn : headersNotLoggedIn;
//     return headersData.map(({ label, href, permissions }) => {
//       if (permissions && permissions === 'admin' && !isAdmin()) return null
//       return (
//         <Link
//           {...{
//             component: RouterLink,
//             to: href,
//             color: "inherit",
//             style: { textDecoration: "none" },
//             key: label,
//           }}
//         >
//           <MenuItem>{label}</MenuItem>
//         </Link>
//       );
//     });
//   };

//   const displayDesktop = () => {
//     return <Toolbar>
//       {d4gridsDemoLogo}
//       {getMenuButtons()}
//     </Toolbar>;
//   };

//   const displayMobile = () => {
//     const handleDrawerOpen = () =>
//     setState((prevState) => ({ ...prevState, drawerOpen: true }));

//     const handleDrawerClose = () =>
//     setState((prevState) => ({ ...prevState, drawerOpen: false }));

//     return (
//       <Toolbar>
//         <IconButton
//           {...{
//             edge: "start",
//             color: "inherit",
//             "aria-label": "menu",
//             "aria-haspopup": "true",
//             onClick: handleDrawerOpen,
//           }}
//         >
//           <MenuIcon />
//         </IconButton>
//         <Drawer
//           {...{
//             anchor: "left",
//             open: drawerOpen,
//             onClose: handleDrawerClose,
//           }}
//         >
//           <div className="">{getDrawerChoices()}</div>
//         </Drawer>
// <div>{d4gridsDemoLogo}</div>
// </Toolbar>
//     );
//   };

//   return <><header>
//       <AppBar>

//         {mobileView ? displayMobile() : displayDesktop()}
//         </AppBar>
//     </header></>;
// };
