import React, { FC, useEffect, useState } from 'react';
import { Box, useToast, Text, Badge, SimpleGrid, transition } from '@chakra-ui/react';
import { UserDevice } from './types';
import { getUserDevices } from '../../utils';

const UserDeviceItem = ({
  onClick,
  item,
}: {
  onClick: (item: UserDevice) => void;
  item: UserDevice;
}): JSX.Element => {
  const { device_id, device_type, integration_id, location_id } = item;
  return (
    <Box
      maxW="sm"
      borderWidth="2px"
      borderRadius="lg"
      overflow="hidden"
      onClick={() => onClick(item)}
      _hover={{
        background: 'white',
        color: 'teal.500',
        border: '2px',
        cursor: 'pointer'
      }
    }
    // transition={'top ease 0.5s '}
    transitionDuration={'0.3s'}
    >
      <Box p="6">
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2" colorScheme="teal">
            {device_type}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            X Measurements &bull; N baths
          </Box>
        </Box>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          noOfLines={1}
        >
          {device_id}
        </Box>

        <Box>
          Location {location_id}
          <Box as="span" color="gray.600" fontSize="sm">
            / {integration_id}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const List: FC<{ onClick: (item: UserDevice) => void }> = ({ onClick }) => {
  const toast = useToast();
  const [error, setError] = useState<unknown>('');
  const [userDevices, setUserDevices] = useState<UserDevice[] | undefined>();

  const queryBackend = async () => {
    try {
      const myDevices = await getUserDevices();
      setUserDevices(myDevices);
      console.log('Devices set', myDevices);
    } catch (err: unknown) {
      console.log('This is the error', err);
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);

  return (
    <Box as="main" maxW="container.lg" textAlign={'left'}>
      <Text size={'xl'}>My Devices</Text>
      {userDevices?.length && (
        <SimpleGrid columns={3} spacingX="20px" spacingY="20px">
          {userDevices.map((item: UserDevice) => (
            <UserDeviceItem key={item.id} item={item} onClick={onClick} />
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default List;
