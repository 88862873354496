import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';

export const DeviceEdit: FC = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="type" />
      <TextInput disabled source="name" />
      <TextInput disabled source="data_source" />
      <TextInput source="measurement_type" />
      <TextInput disabled source="user_id" />
      <TextInput source="location_id" />
      <ArrayInput source="measures">
        <SimpleFormIterator disableRemove>
          <TextInput source="name" />
          <TextInput source="unit" />
          <TextInput source="display_name" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);
