import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
} from 'react-admin';

const MONTHS_HELPER_TEXT = 'Months input 1 = January, 12 = December';
const DAYS_HELPER_TEXT = 'Days input - 0 = Monday, 6 = Sunday';
const HOURS_HELPER_TEXT = 'Hours input - 0 = 00:00, 23 = 23:00';

export const ScheduleEdit: FC = (props) => {
  const transform = (data: any) => ({
    ...data.data,
  });

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="data.der_name" />
        <NumberInput
          source="data.average_power"
          label="Average Power W"
          min={1}
          max={10000}
        />
        <ArrayInput
          source="data.months"
          label="Months"
          helperText={MONTHS_HELPER_TEXT}
        >
          <SimpleFormIterator>
            <NumberInput min={1} max={12} helperText={MONTHS_HELPER_TEXT} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          // label="Days"
          source="data.days"
          helperText={DAYS_HELPER_TEXT}
        >
          <SimpleFormIterator>
            <NumberInput min={0} max={6} helperText={DAYS_HELPER_TEXT} />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          label="Hours"
          source="data.hours"
          helperText={HOURS_HELPER_TEXT}
        >
          <SimpleFormIterator>
            <NumberInput min={0} max={23} helperText={HOURS_HELPER_TEXT} />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
