import React, { FC, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';

import { signUp, isAuthenticated } from '../utils/auth';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftElement,
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  useToast,
} from '@chakra-ui/react';

import { ButtonLink } from '../components/ButtonLink';

import { EmailIcon, LockIcon } from '@chakra-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   margin: {
//     margin: theme.spacing(2),
//   },
//   padding: {
//     padding: theme.spacing(1),
//   },
//   button: {
//     textTransform: 'none',
//   },
//   marginTop: {
//     marginTop: 10,
//   },
// }));

export const SignUp: FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [error, setError] = useState<string>('');
  const toast = useToast();

  const handleSubmit = async (_: React.MouseEvent) => {
    // Password confirmation validation
    if (password !== passwordConfirmation) setError('Passwords do not match');
    else {
      setError('');
      try {
        const data = await signUp(email, password, passwordConfirmation);

        if (data) {
          toast({
            position: 'top',
            isClosable: true,
            status: 'success',
            title: 'Registration complete',
            description: 'Now use your username and password to login',
          });

          history.push('/');
        }
      } catch (err) {
        console.log('Ruho error');
        if (err instanceof Error) {
          // handle errors thrown from frontend
          setError(err.message);
        } else {
          // handle errors thrown from backend
          setError(err);
        }
      }
    }
  };

  return isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <Flex align={'center'} justify={'center'}>
      <Box boxShadow="base" p="6" w="md">
        {error && (
          <ChakraAlert status="error" variant="left-accent">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription display="block">{error}</AlertDescription>
            </Box>
          </ChakraAlert>
        )}

        <Stack spacing={4}>
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<EmailIcon color="gray.300" />}
              />
              <Input
                type="email"
                placeholder="Email *"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.currentTarget.value)
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<LockIcon color="gray.300" />}
              />
              <Input
                type="password"
                placeholder="Password *"
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.currentTarget.value)
                }
              />
            </InputGroup>
          </FormControl>
          <FormControl id="passwordConfirmation" isRequired>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<LockIcon color="gray.300" />}
              />
              <Input
                type="password"
                placeholder="Confirm password *"
                value={passwordConfirmation}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPasswordConfirmation(e.currentTarget.value)
                }
              />
            </InputGroup>
          </FormControl>
          <Stack spacing={10} align="center">
            <ChakraButton
              variant="outline"
              onClick={handleSubmit}
              type="submit"
            >
              Sign Up
            </ChakraButton>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
};
