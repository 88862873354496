import React, { FC } from 'react';
import { fetchUtils, Admin as ReactAdmin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';

import { UserList, UserEdit, UserCreate } from './Users';
import { DeviceList, DeviceEdit } from './Devices';
import { ScheduleEdit, ScheduleList, ScheduleShow } from './schedules';
import { BACKEND_URL } from '../config';

const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Access-Control-Expose-Headers', 'x-total-count');
  console.log('HTTP URL ', url);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(`${BACKEND_URL}/v1`, httpClient);

const myDataProvider = {
  ...dataProvider,
  update: (resource: string, params: any) => {
    if (resource !== 'devices') {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }
    const {
      data: { location, ...dataWithoutLocation },
      ...rest
    } = params;
    return dataProvider.update(resource, {
      data: dataWithoutLocation,
      ...rest,
    });
  },
};

export const Admin: FC = () => {
  return (
    <ReactAdmin dataProvider={myDataProvider} authProvider={authProvider}>
      {(permissions: 'admin' | 'user') => [
        permissions === 'admin' ? (
          <Resource
            name="users"
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
          />
        ) : null,
        permissions === 'admin' ? (
          <Resource name="devices" list={DeviceList} edit={DeviceEdit} />
        ) : null,
        permissions === 'admin' ? (
          <Resource
            name="schedules"
            list={ScheduleList}
            show={ScheduleShow}
            edit={ScheduleEdit}
          />
        ) : null,
      ]}
    </ReactAdmin>
  );
};
