import React, { FC, useState, useEffect } from 'react';

import {
  Box,
  Heading,
  useToast,
  Grid,
  GridItem,
  Text,
  Flex,
  Button,
  Link,
} from '@chakra-ui/react';

import { getAllLocationInfo } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import { FormInputs } from '../devices_info/types';
import { Link as RouterLink } from 'react-router-dom';
import { isAdmin } from '../../utils';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

interface DeviceInfoItem extends FormInputs {
  id: string;
  created_at: Date;
  updated_at: Date;
}

interface MeasurementItem {
  id: number;
  display_name: string;
  device_id: number;
  updated_at: Date;
  measure_type: string;
  name: string;
  created_at: Date;
}

interface DeviceItem {
  id: string;
  device_id: string;
  device_type: string;
  phase_m: string;
  measurements: MeasurementItem[];
}

interface LocationItem {
  name: string;
  lat: number;
  user_id: number;
  id: string;
  country: string;
  lon: number;
  devices_info: DeviceItem[];
}

export const LocationsInfo: FC = () => {
  const toast = useToast();

  const [locations, setDevices] = useState<LocationItem[] | null>(null);

  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const queryBackend = async () => {
    try {
      const response = await getAllLocationInfo();
      setDevices(response);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);

  if (!locations?.length) {
    return (
      <>
        <Heading>My Locations</Heading>
        <Box p={4}>Loading....</Box>
      </>
    );
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading mt="10" mb="10">
          Locations
        </Heading>
        <Link
          rounded={'md'}
          to={`/locations/new`}
          _hover={{
            textDecoration: 'none',
          }}
          as={RouterLink}
        >
          <Button size="md">Add location</Button>
        </Link>
      </Flex>
      {isAdmin() === true ? <Box p={4}><Text>Note that you are logged in as an admin so you can see all users locations and devices.</Text></Box>:''}
      <Grid flexDirection={'row'} gap={6}>
        {locations.map((location) => (
          <GridItem key={location.id}>
            <Box borderWidth="1px" borderRadius="md" p={4}>
              <Heading size="md" mb="2">
                {location.name}
              </Heading>
              <Link
                rounded={'md'}
                to={`/locations/${location.id}/edit`}
                _hover={{
                  textDecoration: 'none',
                }}
                as={RouterLink}
              >
                <Button size="xs">Edit</Button>
              </Link>

              <Text pb="8">
                {location.country} ({location.lat}, {location.lon})
              </Text>
              <Heading mb="2" size="sm">
                Devices ({location.devices_info.length})
              </Heading>
              <Grid
                templateColumns="repeat(auto-fill, minmax(250px, 1fr))"
                gap={4}
              >
                {location.devices_info.length === 0 && (
                  <GridItem>
                    <Box borderWidth="1px" borderRadius="md" p={2}>
                      <Text>
                        There are no devices assigned to this location yet.
                      </Text>
                    </Box>
                  </GridItem>
                )}
                {location.devices_info.map((device) => (
                  <GridItem key={device.id}>
                    <Box borderWidth="1px" borderRadius="md" p={2}>
                      <Flex justifyContent="space-between">
                        <Text fontWeight="bold" fontSize="sm">
                          {device.device_id}
                        </Text>
                        <Link
                          rounded={'md'}
                          to={`/device-info/${device.id}`}
                          _hover={{
                            textDecoration: 'none',
                          }}
                          as={RouterLink}
                        >
                          <Button size="xs">View</Button>
                        </Link>
                      </Flex>
                      <Text>{device.device_type}</Text>
                      <Text>{device.phase_m} phase</Text>
                      <Text>Measurements:</Text>

                      {device.measurements.map((measurement) => (
                        <Flex
                          key={measurement.id}
                          direction="column"
                          borderBottom="1px"
                          borderColor="violet"
                          pb={'2'}
                          pt={'2'}
                        >
                          <Text fontWeight="bold">
                            {measurement.display_name}{' '}
                            {['spoony', 'shelly'].includes(
                              device.device_type
                            ) && device.phase_m === 'single'
                              ? `(${measurement.name})`
                              : ''}
                          </Text>
                          <Flex justifyContent="space-between">
                            <Text>{measurement.measure_type}</Text>
                            {/* <Button size="xs">Edit</Button> */}
                          </Flex>
                        </Flex>
                      ))}
                    </Box>
                  </GridItem>
                ))}
              </Grid>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
