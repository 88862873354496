


import React, { FC, useEffect, useState } from 'react';

import {
  Box, Heading,
} from '@chakra-ui/react';

import Form from './Form';
import List from './List';
import { UserDevice, UserLocation } from './types';
import { getUserLocations } from '../../utils';


export const UserDevices: FC = () => {
  const [selectedUserDevice, setSelectedUserDevice] = useState<UserDevice | null>(null);
  const [userLocations, setUserLocations] = useState<UserLocation[]>([]);
  const queryBackend = async () => {
      try {
        const userLocations = await getUserLocations();
        setUserLocations(userLocations);
        console.log('Locations set', userLocations);
      } catch (err: unknown) {
        console.log('This is the error', err);
      }
    };
  
    useEffect(() => {
      queryBackend();
    }, []);

  
  const handleChange = (value: UserDevice | null) => {
    setSelectedUserDevice(value);
  };


  

  return (
    <Box align={'center'} justify={'center'}>
      <List  onClick={(value) => handleChange(value)} />
      <Heading>New/Edit Device</Heading>
      <Form selectedUserDevice={selectedUserDevice} userLocations={userLocations} />
    </Box>
  );
};
