import React, { ReactElement, ReactNode } from 'react';
import { Link, Button, ButtonProps } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

interface ButtonLinkProps {
  title: string;
  href: string;
}

export const ButtonLink = ({
  title,
  href,
  variant,
  colorScheme,
  w,
}: ButtonLinkProps & ButtonProps) => {
  return (
    <Link
      // px={2}
      // py={1}
      rounded={'md'}
      to={href}
      _hover={{
        textDecoration: 'none',
      }}
      as={RouterLink}
    >
      <Button
        variant={variant || 'solid'}
        colorScheme={colorScheme || 'blue'}
        w={w}
      >
        {title}
      </Button>
    </Link>
  );
};
