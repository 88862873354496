// in src/devices.js
import React, { FC } from 'react';

import {
  ArrayField,
  List,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  EmailField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
} from 'react-admin';

export const ScheduleShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="der_name" label="DER Name" />
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="data.average_power" label="Average Power" />
      <DateField source="updated_at" label="Updated" />
      <TextField source="data.months" />
      <TextField source="data.days" />
      <TextField source="data.hours" />
    </SimpleShowLayout>
  </Show>
);
