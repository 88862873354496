const isProduction = process.env.NODE_ENV === 'production';
export const BASE_URL: string = isProduction
  ? ''
  : 'https://app.d4gs.local';
export const BACKEND_URL: string = isProduction
  ? '/api'
  : 'https://app.d4gs.local/api';
export const BACKEND_URL_NAKED: string = isProduction
  ? ''
  : 'https://app.d4gs.local';
