import { CheckIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { useEffect, useState } from 'react';
import {
  dayLabels,
  EditMode,
  hourLabels,
  monthLabels,
  ScheduleItem,
} from '../../utils/types';
import { ScheduleTag } from './ScheduleTag';

import { intersection } from 'ramda';

export const ScheduleEditor = ({
  id,
  scheduleData,
  children,
  mode,
  onSave,
  cancel,
}: {
  id: string;
  mode: EditMode;
  onSave(id: string, data: ScheduleItem['data']): void;
  cancel(): void;
  scheduleData: ScheduleItem['data'];
  children?: React.ReactNode;
}) => {
  const [schedule, setSchedules] = useState<ScheduleItem['data']>(scheduleData);
  const [modified, setModified] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | undefined>();

  const { schedules } = schedule;
  useEffect(() => {
    console.log('Loaded the edit with ', schedule);

    if (schedule.schedules !== undefined && schedule.schedules.length === 2) {
      const monthsIntersection = intersection(
        schedule.schedules[0].months,
        schedule.schedules[1].months
      );
      if (monthsIntersection.length >= 1) {
        setValidationError(
          `Months are not allowed to overlap between schedules: ${JSON.stringify(
            monthsIntersection
          )}`
        );
      } else {
        setValidationError(undefined);
      }
    }
  }, [schedule]);

  const handleClick = async (
    _: React.MouseEvent | React.ChangeEvent | undefined,
    value: string | number,
    key: string,
    index?: number
  ) => {
    console.log('handle click', value, key);
    if (
      index === undefined &&
      (key === 'months' || key === 'days' || key === 'hours')
    ) {
      const monthAlreadyExists = schedule[key].findIndex(
        (month) => month === value
      );

      if (monthAlreadyExists === -1) {
        setSchedules({
          ...schedule,
          [key]: [...schedule[key], value as number],
        });
      } else {
        console.log('Need to remove value somehow');
        setSchedules({
          ...schedule,
          [key]: schedule[key].filter((month) => month !== value),
        });
      }
      setModified(true);
    }

    if (index === undefined && key === 'der_name') {
      setSchedules({
        ...schedule,
        [key]: value as string,
      });
      setModified(true);
    }

    if (index === undefined && key === 'average_power') {
      setSchedules({
        ...schedule,
        [key]: +value as number,
      });
      setModified(true);
    }

    if (
      schedule.schedules !== undefined &&
      index !== undefined &&
      key === 'average_power'
    ) {
      const scheduleItem = schedule.schedules[index];
      const modified = {
        ...scheduleItem,
        [key]: +value as number,
      };

      setSchedules({
        ...schedule,
        schedules: Object.assign([], schedule.schedules, {
          [index]: modified,
        }),
      });
      setModified(true);
    }
    if (
      schedule.schedules !== undefined &&
      index !== undefined &&
      (key === 'months' || key === 'days' || key === 'hours')
    ) {
      console.log('Editing schedule which contains multiple parts');
      const scheduleItem = schedule.schedules[index];
      const monthAlreadyExists = scheduleItem[key].findIndex(
        (month) => month === value
      );

      if (monthAlreadyExists === -1) {
        console.log('Updating months of index', index, key);
        const modified = {
          ...scheduleItem,
          [key]: [...scheduleItem[key], value as number],
        };

        setSchedules({
          ...schedule,
          schedules: Object.assign([], schedule.schedules, {
            [index]: modified,
          }),
        });
        setModified(true);
      } else {
        console.log('Updating months of index', index, key);
        const modified = {
          ...scheduleItem,
          [key]: scheduleItem[key].filter((month) => month !== value),
        };

        setSchedules({
          ...schedule,
          schedules: Object.assign([], schedule.schedules, {
            [index]: modified,
          }),
        });
      }
    }
  };

  const addSplitSchedule = () => {
    const index = schedules?.length ?? 0;

    setSchedules({
      ...schedule,
      schedules: [
        {
          months: schedule.months,
          days: schedule.days,
          hours: schedule.hours,
          average_power: schedule.average_power,
        },
        {
          months: schedule.months,
          days: schedule.days,
          hours: schedule.hours,
          average_power: schedule.average_power + 1,
        },
      ],
    });
  };

  return (
    <>
      <Button
        leftIcon={<CheckIcon />}
        colorScheme="teal"
        variant="outline"
        size={'sm'}
        onClick={() => onSave(id, schedule)}
        disabled={!modified}
      >
        Save
      </Button>
      <Button
        leftIcon={<CloseIcon />}
        colorScheme="gray.700"
        variant="ghost"
        size={'sm'}
        onClick={() => cancel()}
      >
        Cancel
      </Button>
      <>
        <FormLabel htmlFor="der_name">DER Name</FormLabel>
        <Input
          placeholder="Add a der schedule name"
          value={schedule.der_name}
          onChange={(e) => handleClick(e, e.target.value, 'der_name')}
        />
      </>
      <>
        <FormLabel htmlFor="average_power">Average Active Power (W)</FormLabel>
        <NumberInput
          value={schedule.average_power}
          onChange={(valueString) =>
            handleClick(undefined, valueString, 'average_power')
          }
          min={1}
          max={10000}
          step={50}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </>
      <FormLabel>
        Months / Days / Hours of operation{' '}
        {schedules !== undefined && <>DISABLED</>}
      </FormLabel>
      {schedules !== undefined && (
        <Alert status="info">
          <AlertIcon />
          This main schedule is disabled and cannot be edited when the schedule
          is split.
        </Alert>
      )}
      <Box display={schedules == undefined ? '' : 'none'}>
        <Box p={2}>
          <Wrap>
            {Object.keys(monthLabels)
              .map(Number)
              .map((month: number) => (
                <ScheduleTag
                  value={month}
                  compareList={schedule.months}
                  labels={monthLabels}
                  onClick={(e) => handleClick(e, month, 'months')}
                  clickable={schedules === undefined}
                />
              ))}
          </Wrap>
        </Box>
        <Box p={2}>
          <Wrap>
            {Object.keys(dayLabels)
              .map(Number)
              .map((day: number) => (
                <ScheduleTag
                  value={day}
                  compareList={schedule.days}
                  labels={dayLabels}
                  onClick={(e) => handleClick(e, day, 'days')}
                  clickable={schedules === undefined}
                />
              ))}
          </Wrap>
        </Box>
        <Box p={2}>
          <Wrap>
            {Object.keys(hourLabels)
              .map(Number)
              .map((hour: number) => (
                <ScheduleTag
                  value={hour}
                  compareList={schedule.hours}
                  labels={hourLabels}
                  onClick={(e) => handleClick(e, hour, 'hours')}
                  clickable={schedules === undefined}
                />
              ))}
          </Wrap>
        </Box>
      </Box>
      <>
        {validationError !== undefined && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Schedules not compatible!</AlertTitle>
            <AlertDescription>{validationError}</AlertDescription>
          </Alert>
        )}
      </>
      <>
        {schedules !== undefined &&
          schedules.map((item, index) => (
            <Box key={index}>
              <FormLabel>
                Schedule Part ({index}): Months / Days / Hours of operation
              </FormLabel>
              <>
                <FormLabel htmlFor="average_power">
                  Average Active Power (W)
                </FormLabel>
                <NumberInput
                  value={schedules[index].average_power}
                  onChange={(valueString) =>
                    handleClick(undefined, valueString, 'average_power', index)
                  }
                  min={1}
                  max={10000}
                  step={50}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </>
              <Box p={2}>
                <Wrap>
                  {Object.keys(monthLabels)
                    .map(Number)
                    .map((month: number) => (
                      <ScheduleTag
                        value={month}
                        compareList={schedules[index].months}
                        labels={monthLabels}
                        onClick={(e) => handleClick(e, month, 'months', index)}
                        clickable={true}
                      />
                    ))}
                </Wrap>
              </Box>
              <Box p={2}>
                <Wrap>
                  {Object.keys(dayLabels)
                    .map(Number)
                    .map((day: number) => (
                      <ScheduleTag
                        value={day}
                        compareList={schedule.days}
                        labels={dayLabels}
                        onClick={(e) => handleClick(e, day, 'days', index)}
                        clickable={true}
                      />
                    ))}
                </Wrap>
              </Box>
              <Box p={2}>
                <Wrap>
                  {Object.keys(hourLabels)
                    .map(Number)
                    .map((hour: number) => (
                      <ScheduleTag
                        value={hour}
                        compareList={schedule.hours}
                        labels={hourLabels}
                        onClick={(e) => handleClick(e, hour, 'hours', index)}
                        clickable={true}
                      />
                    ))}
                </Wrap>
              </Box>
            </Box>
          ))}
      </>
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        variant="outline"
        size={'sm'}
        onClick={() => addSplitSchedule()}
        disabled={schedules !== undefined}
      >
        Split schedule
      </Button>
    </>
  );
};
