import React, { useState, useEffect } from 'react';

import {
  Box,
  Heading,
  Text,
  Code,
  Button,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
} from '@chakra-ui/react';
import { getNetatmoHomesData } from '../../utils/api';

interface Props {}

export interface NetatmoResponse {
  meta?: any;
  results?: any;
}

export const NetatmoHomes = (props: Props) => {
  const [integrationResults, setIntegrationsResults] =
    useState<NetatmoResponse | undefined>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const queryBackend = async () => {
    try {
      setLoading(true);
      const myIntegrations = await getNetatmoHomesData();
      setIntegrationsResults(myIntegrations);
    } catch (err) {
      setLoading(false);
      console.log('This is the error', err);
      setError(err);
    }
  };

  return (
    <Box paddingTop="4" paddingBottom="4">
      <Heading>Netatmo</Heading>
      <Text>
        Using the button below you can list the netatmo homes we can fetch via
        the API integration.
      </Text>

      <Button
        onClick={() => queryBackend()}
        colorScheme="teal"
        size="md"
        disabled={false}
      >
        Get homes data
      </Button>

      {integrationResults && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {error}
            <pre>{JSON.stringify(integrationResults, null, 2)}</pre>
            {/* <Stat>
              <StatLabel>
                Meter {integrationResults.results?.meter_reading.usage_point_id}
              </StatLabel>
              <StatNumber>
                {integrationResults.results?.meter_reading.quality}
              </StatNumber>
              <StatHelpText>
                {integrationResults.results?.meter_reading.start} -{' '}
                {integrationResults.results?.meter_reading.end}
              </StatHelpText>
            </Stat>
            <Text>Readings</Text>
            <Table variant="striped" size="sm" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th isNumeric>Value (W)</Th>
                  <Th isNumeric>Interval</Th>
                  <Th isNumeric>Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {integrationResults.results &&
                  integrationResults.results.meter_reading.interval_reading.map(
                    ({
                      value,
                      date,
                      interval_length,
                      measure_type,
                    }: {
                      value: string;
                      date: string;
                      interval_length: string;
                      measure_type: string;
                    }) => {
                      return (
                        <Tr>
                          <Td>{date}</Td>
                          <Td isNumeric>{value}</Td>
                          <Td>{interval_length}</Td>
                          <Td isNumeric>{measure_type}</Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table> */}
          </Code>
        </Box>
      )}

      {!integrationResults && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {!loading ? (
              <pre>Click the button above to request your data</pre>
            ) : (
              <Spinner size="xl" />
            )}
          </Code>
        </Box>
      )}
    </Box>
  );
};
