import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Flex,
  Spacer,
  AspectRatio,
  Text,
  Link,
  Spinner,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  Button,
} from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';
import { BACKEND_URL_NAKED } from '../config';
import { getGrafanaDashboards } from '../utils';

export interface GrafanDashboardItem {
  id: number;
  uid: string;
  title: string;
  uri: string;
  url: string;
  slug: string;
  type: string;
  tags: string[];
  isStarred: boolean;
  sortMeta: number;
  folderId?: number;
  folderUid?: string;
  folderTitle?: string;
  folderUrl?: string;
}

const FLEX_FOLDER_TITLE: string = 'My Flex -';
const OTHER_FLEX_FOLDER_TITLE: string = 'My ';
const NO_FOLDER_NAME: string = 'General';

/**
 * Index a list of features by their parent folder.
 */
const collectDashboardsByFolder = (featureMap: GrafanDashboardItem[]) => {
  const featuresByFolder = new Map<string | null, GrafanDashboardItem[]>();
  for (const feature of featureMap) {
    if (feature.folderTitle !== undefined) {
      const group = featuresByFolder.get(feature.folderTitle) || [];
      group.push(feature);
      featuresByFolder.set(feature.folderTitle, group);
    } else {
      const group = featuresByFolder.get(NO_FOLDER_NAME) || [];
      group.push(feature);
      featuresByFolder.set(NO_FOLDER_NAME, group);
    }
  }
  return featuresByFolder;
};

const renderItem = ({
  feature: { title, url },
  changeUrl,
}: {
  feature: GrafanDashboardItem;
  changeUrl(url: string): void;
}) => {
  return (
    <Box p={2}>
      <Link onClick={() => changeUrl(url)}>
        <Text fontSize="xs">{title}</Text>
      </Link>
    </Box>
  );
};

const renderMenuItem = ({
  feature: { title, url, id },
  changeUrl,
  iframeUrl,
}: {
  feature: GrafanDashboardItem;
  changeUrl(url: string): void;
  iframeUrl: string;
}) => {
  const isCurrentSelected = iframeUrl.includes(url);
  return (
    <MenuItem
      key={id}
      onClick={() => changeUrl(url)}
      fontWeight={isCurrentSelected === true ? 'bold' : ''}
      bg={isCurrentSelected === true ? 'orange.100' : ''}
    >
      {title}
    </MenuItem>
  );
};

const renderFolders = ({
  features,
  changeUrl,
}: {
  features: GrafanDashboardItem[];
  changeUrl(url: string): void;
}) => {
  const featuresMap = collectDashboardsByFolder(features);

  const folderNames = Array.from(featuresMap.keys());

  return folderNames.map((name) => {
    return (
      <Box>
        <Text fontSize="xs" fontWeight={'medium'}>
          {name}
        </Text>
        <Box>
          {featuresMap
            .get(name)
            ?.map((feature) => renderItem({ feature, changeUrl }))}
        </Box>
      </Box>
    );
  });
};

const renderFoldersMenu = ({
  features,
  changeUrl,
  iframeUrl,
}: {
  features: GrafanDashboardItem[];
  changeUrl(url: string): void;
  iframeUrl: string;
}) => {
  const featuresMap = collectDashboardsByFolder(features);

  const folderNames = Array.from(featuresMap.keys());

  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme="pink"
        rightIcon={<ChevronDownIcon />}
      >
        Change Dashboard
      </MenuButton>
      <MenuList>
        {folderNames.map((name) => {
          return (
            <MenuGroup key={name} title={name ?? 'NoName'}>
              {featuresMap
                .get(name)
                ?.map((feature) =>
                  renderMenuItem({ feature, changeUrl, iframeUrl })
                )}
            </MenuGroup>
          );
        })}
        <MenuDivider />
      </MenuList>
    </Menu>
  );
};

export const GrafanaDashboards: FC = () => {
  const [error, setError] = useState<unknown>('');
  const [dashboardItems, setDashboardItems] = useState<GrafanDashboardItem[]>();
  const [iframeUrl, setIframeUrl] = useState(
    `${BACKEND_URL_NAKED}/grafana/d/h801NnY7z/my-flex-power-dashboard?orgId=1&kiosk=tv`
  );
  const [loadingDashboards, setLoadingDashboards] = useState<boolean>(true);
  const [dashboardTitle, setDashboardTitle] = useState<string>('');

  const queryBackend = async () => {
    try {
      const dashboards = await getGrafanaDashboards('');
      if (dashboards !== undefined && Array.isArray(dashboards)) {
        const filteredList = dashboards.filter(
          (dashboard: GrafanDashboardItem): boolean =>
            dashboard.tags.includes('production') === true
          // dashboard.folderTitle !== undefined &&
          // (dashboard.folderTitle.includes(FLEX_FOLDER_TITLE) === true || dashboard.title.includes(OTHER_FLEX_FOLDER_TITLE) === true)
        );

        setDashboardItems(filteredList);

        if (filteredList.length > 0 === true) {
          const dashboardOnly = filteredList.filter(
            (dashboard: GrafanDashboardItem): boolean =>
              dashboard.type === 'dash-db'
          );

          setIframeUrl(`${BACKEND_URL_NAKED}${dashboardOnly[0].url}?kiosk=tv`);
          setLoadingDashboards(false);
        } else {
          setIframeUrl(`${BACKEND_URL_NAKED}/grafana?kiosk=tv`);
          setLoadingDashboards(false);
        }
      } else {
        setDashboardItems([]);
        setLoadingDashboards(false);
        setIframeUrl(`${BACKEND_URL_NAKED}/grafana?kiosk=tv`);
      }
    } catch (err: unknown) {
      console.log('This is the error', err);
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);

  const clickedRenderItem = (url: string) => {
    setIframeUrl(`${BACKEND_URL_NAKED}${url}?kiosk=tv`);
  };

  useEffect(() => {
    const matchingDashboard = dashboardItems?.find(
      (dashboard) => iframeUrl.includes(dashboard.url) === true
    );
    if (matchingDashboard !== undefined) {
      setDashboardTitle(matchingDashboard.title);
    }
  }, [iframeUrl]);

  return (
    <>
      <Box boxShadow="base" p="6">
        <Flex alignItems="center" gap="2" direction={['column', 'row']}>
          <Heading as="h1" size={'md'} noOfLines={1}>My Dashboards</Heading>
          <Heading as="h3" size={'md'} noOfLines={1}>
            {dashboardTitle !== '' ? ` - ${dashboardTitle}` : ''}
          </Heading>
          <Spacer />
          {/* <Box >
            {dashboardItems && dashboardItems.length > 0 && renderFolders({features: dashboardItems, changeUrl: clickedRenderItem})}
          </Box> */}
          <Box>
            {dashboardItems &&
              dashboardItems.length > 0 &&
              renderFoldersMenu({
                features: dashboardItems,
                changeUrl: clickedRenderItem,
                iframeUrl,
              })}
          </Box>
        </Flex>
      </Box>
      {error != '' && (
        <Center padding={20}>
          <Text align={'center'} fontSize={'2xl'}>
            There was an error loading your dashboards or they have not been
            enabled yet please try again later.
          </Text>
        </Center>
      )}
      {loadingDashboards === true && error === '' && (
        <Spinner color="red.500" />
      )}
      {loadingDashboards === false && (
        <AspectRatio ratio={[9/16,9/16, 16 / 9]}>
          <iframe src={iframeUrl} name="demo" />
        </AspectRatio>
      )}
    </>
  );
};
