import { BACKEND_URL, BACKEND_URL_NAKED } from '../config';

export const getMessage = async () => {
  const response = await fetch(`${BACKEND_URL}/v1`);

  const data = await response.json();

  if (data.message) {
    return data.message;
  }

  return Promise.reject('Failed to get message from backend');
};

export const getLegrandLink = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL_NAKED}/legrand/link`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (data.link) {
    return data.link;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getLinkyConnectLink = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/integrations/linky/link`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (data.link) {
    return data.link;
  }

  return Promise.reject('Failed to get linky connect link from backend');
};

export const getNetatmoLink = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/integrations/netatmo/link`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (data.link) {
    return data.link;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getProfile = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/v1/users/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (response.status !== 200) {
    console.log('Response status is', response.statusText);
    // throw new Error(`Error on getprofile ${response.status}`);
    // return Promise.reject('Failed to get profile from backend');
    throw Error(`Failed to authenticate user profile - ${response.status}`)
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get profile from backend');
};

export const getMyIntegrations = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/legrand/my-integrations`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getMyPlants = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/legrand/get_plants/hc`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getLinkyMeterConsumption = async (
  linkyMeter: string | undefined
) => {
  if (!linkyMeter) {
    return Promise.reject('You did not send the linkyMeter param');
  }
  const token = localStorage.getItem('token');

  const response = await fetch(
    `${BACKEND_URL}/integrations/linky/my/${linkyMeter}/consumption?api_key=97857178-c550-11eb-8529-0242ac130003`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getLinkyMeterProduction = async (
  linkyMeter: string | undefined
) => {
  if (!linkyMeter) {
    return Promise.reject('You did not send the linkyMeter param');
  }
  const token = localStorage.getItem('token');

  const response = await fetch(
    `${BACKEND_URL}/integrations/linky/my/${linkyMeter}/production?api_key=97857178-c550-11eb-8529-0242ac130003`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();
  console.log('GOT THIS PRODUCTION DATA', data)
  if (data) {
    return data;
  }

  return Promise.reject('Failed to get legrand connect link from backend');
};

export const getNetatmoHomesData = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(
    `${BACKEND_URL}/integrations/netatmo/my/homesdata`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get netatmo connect link from backend');
};

export const getMySchedules = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/v1/schedules`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get schedules');
};

export const updateMySchedule = async (id: string, inputData: unknown) => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/schedules/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to update schedule');
};

export const createMySchedule = async (inputData: unknown) => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/schedules/manual_weekahead`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create schedule');
};


export const getGrafanaDashboards = async (searchString: string) => {

  const params = new URLSearchParams({query: searchString}).toString()

  const request = new Request(`${BACKEND_URL_NAKED}/grafana/api/search?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get grafana dashboards');
};


export const getUserDevices = async () => {

  const params = new URLSearchParams({}).toString()
  const token = localStorage.getItem('token');
  
  const request = new Request(`${BACKEND_URL}/v1/user_devices?${params}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get user devices');
};

export const createUserDevice = async (inputData: unknown) => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/user_devices`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create user device');
};

export const getUserLocations = async () => {

  const token = localStorage.getItem('token');
  
  const request = new Request(`${BACKEND_URL}/v1/locations/my`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get user locations');
};

export const postAcceptTos = async () => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/users/me/accept_tos`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to accept tos');
};



export const updateMyAccount = async (user_id: number, inputData: unknown) => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/users/${user_id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (data) {
    return data;
  }

  return Promise.reject('Failed to update user account');
};


export const getAllUsers = async () => {
  const token = localStorage.getItem('token');

  const response = await fetch(`${BACKEND_URL}/v1/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (response.status !== 200) {
    console.log('Response status is', response.statusText);
    // throw new Error(`Error on getprofile ${response.status}`);
    // return Promise.reject('Failed to get profile from backend');
    throw Error(`Failed to authenticate users - ${response.status}`)
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get users from backend');
};

export const getAllDeviceInfo = async () => {
  const token = localStorage.getItem('token');
  
  const response = await fetch(`${BACKEND_URL}/v1/device_info/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (response.status !== 200) {
    console.log('Response status is', response.statusText);
    // throw new Error(`Error on getprofile ${response.status}`);
    // return Promise.reject('Failed to get profile from backend');
    throw Error(`Failed to authenticate device info - ${response.status}`)
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get device info from backend');
};

export const getDeviceInfo = async (device_id: string) => {
  const token = localStorage.getItem('token');
  // return Promise.resolve({
  //   id: "123", 
  //   device_id: "spoony.ls-tesla",
  //   device_type: "spoony",
  //   phase_m: "single",
  //   location_id: "somewhere",
  //   owner_id: 4
  // })
  const response = await fetch(`${BACKEND_URL}/v1/device_info/${device_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (response.status !== 200) {
    console.log('Response status is', response.statusText);
    // throw new Error(`Error on getprofile ${response.status}`);
    // return Promise.reject('Failed to get profile from backend');
    throw Error(`Failed to authenticate device info - ${response.status}`)
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get device info from backend');
};



export const updateDeviceInfo = async (inputData: unknown, id: string) => {
  const token = localStorage.getItem('token');
  console.log('updateDeviceInfo device called')
  // return Promise.resolve({data:"success"})

  const request = new Request(`${BACKEND_URL}/v1/device_info/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create device info');
};

export const createDeviceInfo = async (inputData: unknown) => {
  const token = localStorage.getItem('token');
  console.log('Create device called')

  const request = new Request(`${BACKEND_URL}/v1/device_info`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create device info');
};


export const createDeviceMetadataInfo = async (inputData: unknown) => {
  const token = localStorage.getItem('token');
  console.log('Create DeviceMetadataInfo called')

  const request = new Request(`${BACKEND_URL}/v1/device_measurement_metadata`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create device metadata info');
};

export const updateDeviceMetadataInfo = async (inputData: unknown, id: string) => {
  const token = localStorage.getItem('token');
  console.log('updateDeviceMetadataInfo device called')
  // return Promise.resolve({data:"success"})

  const request = new Request(`${BACKEND_URL}/v1/device_measurement_metadata/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to update device info');
};

export const getAllLocationInfo = async () => {
  const token = localStorage.getItem('token');
  
  const response = await fetch(`${BACKEND_URL}/v1/locations1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    credentials: 'include',
  });

  const data = await response.json();

  if (response.status !== 200) {
    console.log('Response status is', response.statusText);
    // throw new Error(`Error on getprofile ${response.status}`);
    // return Promise.reject('Failed to get profile from backend');
    throw Error(`Failed to authenticate device info - ${response.status}`)
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get locations info from backend');
};

export const createLocation = async (inputData: unknown) => {
  const token = localStorage.getItem('token');
  console.log('Create DeviceMetadataInfo called')

  const request = new Request(`${BACKEND_URL}/v1/locations`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create device metadata info');
};

export const getLocationById = async (location_id: string) => {
  const token = localStorage.getItem('token');

  const request = new Request(`${BACKEND_URL}/v1/locations1/${location_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to get location info');
};

export const updateLocation = async (inputData: unknown, location_id: string) => {
  const token = localStorage.getItem('token');
  console.log('Create DeviceMetadataInfo called')

  const request = new Request(`${BACKEND_URL}/v1/locations/${location_id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(inputData),
  });

  const response = await fetch(request);

  const data = await response.json();

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if (data) {
    return data;
  }

  return Promise.reject('Failed to create device metadata info');
};
