export enum DataSource {
  UNKNOWN_DATA_SOURCE = 'unknown_data_source',
  MQTT = 'mqtt',
  LINKY_API = 'linky_api',
  NETATMO_API = 'netatmo_api',
  LEGRAND_API = 'legrand_api',
  SHELLY = 'shelly_api',
}

export enum SmartApplianceCategory {
  PERIODICAL_APPLIANCES = 'periodical_appliances',
  CONTINUOUS_APPLIANCES = 'continuous_appliances',
  BEHAVIORAL_APPLIANCES = 'behavioral_appliances',
  HVAC = 'hvac',
  BATTERY_OPERATED_RECHARGEABLE_APPLIANCES = 'battery_operated_rechargeable_appliances',
  RESIDENTIAL_ENERGY_STORAGE_SYSTEM = 'residential_energy_storage_system',
}

export interface Channel {
  name: string;
  classification: string;
  prefix?: string;
  suffix?: string;
  metadata?: {
    is: {
      programmable: boolean;
    };
    measurements: Measurement[];
    smartApplianceCategory?: SmartApplianceCategory;
  };
}

export interface Measurement {
  unit: string;
  databaseMeasurementPrefix: string;
}

export interface Data {
  source: DataSource;
  channels: Channel[];

  mqtt?: {
    topic: string;
    user: string;
    host: string;
  };
}

export enum DeviceType {
  DEVICE_TYPE_UNKNOWN = 'unknown_device_type',
  LEGRAND = 'legrand',
  LINKY = 'linky',
  NETATMO = 'netatmo',
  SHELLY = 'shelly',
  SPOONY = 'spoony',
}
export interface Inputs {
  device_id: string;
  device_type: string;
  integration_id: string;
  location_id: string;
  data: Data;
}

export type UserDevice = Inputs & {
  owner: number;
  id: string;
};

export interface UserLocation {
  name: string;
  country: string;
  lat: number;
  lon: number;
  user_id: number;
  id: string;
}
