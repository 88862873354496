import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router';

import {
  Home,
  Login,
  SignUp,
  Protected,
  PrivateRoute,
  LegrandSetup,
  Logout,
  ForgotPassword,
  ResetPassword,
  Schedules,
  UserDevices
} from './views';
import { Admin } from './admin';
import { isAuthenticated } from './utils/auth';

import { Header } from './components/Header';

// add chakra
import { ChakraProvider, ColorModeScript, Flex, Box } from '@chakra-ui/react';
import useAuth from './hooks/useAuth';
import { GrafanaDashboards } from './views/GrafanaDashboards';
import { Footer } from './components/Footer';
import { Integrations } from './views/Integrations';
import { DeviceInfo } from './views/devices_info/DeviceInfo';
import { NewDeviceInfo } from './views/devices_info/New';
import { EditDeviceInfo } from './views/devices_info/Edit';
import { ViewDeviceInfo } from './views/devices_info/View';
import { LocationsInfo } from './views/locations/Index';
import { EditLocation } from './views/locations/Edit';
import { NewLocation } from './views/locations/New';

// const useStyles = makeStyles((theme) => ({
//   app: {
//     textAlign: 'center',
//     display: 'grid',
//     gridTemplateRows: '4rem 1fr',
//   },
//   header: {
//     backgroundColor: '#282c34',
//     minHeight: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     fontSize: 'calc(10px + 2vmin)',
//     color: 'white',
//   },
// }));

const Main: FC = ({ children }) => <Box w="100%" display="flex" flexDir="column" flex='1'>{children}</Box>;

export const Routes: FC = () => {
  // const classes = useStyles();
  const history = useHistory();

  return (
    <ChakraProvider>
      <ColorModeScript initialColorMode="light"></ColorModeScript>
      <Switch>
        <Route path="/admin">
          <Admin />
        </Route>

        {/* <div className={classes.app}>
          <Header autheticated={isAuthenticated()} />
          <div className={classes.header}>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route
              path="/logout"
              render={() => {
                logout();
                history.push('/');
                return null;
              }}
            />
            <PrivateRoute path="/protected" component={Protected} />
            <PrivateRoute path="/integrations-setup" component={LegrandSetup} />
            <Route exact path="/" component={Home} />
          </div>
        </div> */}
       
      </Switch>
      <Flex direction="column" color="gray.800" minH={'100vh'}>
          <Header autheticated={isAuthenticated()} />
          <Main >
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/logout" component={Logout} />
            <PrivateRoute path="/protected" component={Protected} exact />
            <PrivateRoute path="/device-info" component={DeviceInfo} exact />
            <PrivateRoute path="/device-info/new" component={NewDeviceInfo} exact />
            <PrivateRoute path="/device-info/:deviceId/edit" component={EditDeviceInfo} exact />
            <PrivateRoute path="/device-info/:deviceId" component={ViewDeviceInfo} exact />
            
            <PrivateRoute path="/locations/" component={LocationsInfo} exact />
            <PrivateRoute path="/locations/new" component={NewLocation} exact />
            
            <PrivateRoute path="/locations/:locationId/edit" component={EditLocation} exact />

            <PrivateRoute
              path="/integrations"
              component={Integrations}
              exact
            />
            <PrivateRoute
              path="/integrations-setup"
              component={LegrandSetup}
              exact
            />
            <PrivateRoute path="/schedules" component={Schedules} exact />
            <PrivateRoute path="/dashboards" component={GrafanaDashboards} exact />
            <PrivateRoute path="/user-devices" component={UserDevices} exact />
            <Route exact path="/" component={Home} />
            </Switch>
          </Main>
          <Footer autheticated={isAuthenticated()} />
        </Flex>
    </ChakraProvider>
  );
};
