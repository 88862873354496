import React, { FC, useState, useEffect } from 'react';

import { getMyIntegrations } from '../../utils/api';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Heading,
  Box,
} from '@chakra-ui/react';
import { parseJSON, format } from 'date-fns';
import { ButtonLink } from '../ButtonLink';

export interface IntegrationsListProps {}

interface IObjectKeys {
  [key: string]: string | number | undefined;
}
const IntegrationTitles: IObjectKeys = {
  linky: 'Linky/Enedis Meter',
  legrand: 'Legrand IOT',
  legrand_up: 'Legrand IOT ()',
  netatmo: 'Netatmo/Legrand'
};

export interface IntegrationsListItem {
  id: number;
  user_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  userid: number;
  resource: string;
}

export const IntegrationsList: FC<IntegrationsListProps> = ({}) => {
  const [integrations, setIntegrations] =
    useState<IntegrationsListItem[] | undefined>();
  const [error, setError] = useState<string>('');

  const queryBackend = async () => {
    try {
      const myIntegrations = await getMyIntegrations();
      setIntegrations(myIntegrations.results);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);

  return (
    <Box paddingTop="4" paddingBottom="4">
      <Heading as="h1" marginBottom={2}>
        My Integrations
      </Heading>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Integration Name</Th>
            <Th>Meter ID(s)</Th>
            <Th isNumeric>Created</Th>
            <Th isNumeric>Last Updated</Th>
          </Tr>
        </Thead>
        <Tbody>
          {integrations &&
            integrations.map(
              ({ id, name, created_at, updated_at, resource }) => {
                return (
                  <Tr key={id}>
                    <Td>{IntegrationTitles[name]}</Td>
                    <Td>{name === 'netatmo' ? 'not available' : resource}</Td>
                    <Td isNumeric>
                      {format(parseJSON(created_at), 'dd MMM yyyy')}
                    </Td>
                    <Td isNumeric>
                      {format(parseJSON(updated_at), 'dd MMM yyyy')}
                    </Td>
                  </Tr>
                );
              }
            )}
        </Tbody>
      </Table>
      <ButtonLink mt="2" href={`/integrations-setup`} title="Add/Update integration →"/>
    </Box>
  );
};
