import { Tag, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { KeyMap } from '../../utils/types';

export const ScheduleTag = ({
  value,
  compareList,
  labels,
  onClick,
  clickable,
}: {
  clickable: boolean;
  value: number;
  compareList: number[];
  labels: KeyMap;
  onClick?: React.MouseEventHandler;
}) => {
  return (
    <WrapItem key={value}>
      <Tag
        size={'md'}
        variant="solid"
        colorScheme={compareList.includes(value) === true ? 'green' : 'gray'}
        onClick={onClick}
        cursor={clickable === true ? 'pointer' : 'auto'}
      >
        {labels[value]}
      </Tag>
    </WrapItem>
  );
};
