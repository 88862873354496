import React, { FC, useState } from 'react';

import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';

import { isAuthenticated, sendPasswordResetEmail } from '../utils/auth';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  Stack,
  InputGroup,
  InputLeftElement,
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Flex,
  useToast,
} from '@chakra-ui/react';


import { EmailIcon } from '@chakra-ui/icons';

export const ForgotPassword: FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const toast = useToast();

  const handleSubmit = async (_: React.MouseEvent) => {
      setError('');
      try {
        const data = await sendPasswordResetEmail(email);
        
        if (data) {
          toast({
            position: 'top',
            isClosable: true,
            status: 'success',
            title: 'Password reset email sent',
            description: 'Check your email',
          });

          history.push('/');
        }
      } catch (err) {
        console.log('Ruho error');
        if (err instanceof Error) {
          // handle errors thrown from frontend
          setError(err.message);
        } else {
          // handle errors thrown from backend
          setError(err);
        }
      }
  };

  return isAuthenticated() ? (
    <Redirect to="/" />
  ) : (
    <Flex align={'center'} justify={'center'}>
      <Box boxShadow="base" p="6" w="md">
        {error && (
          <ChakraAlert status="error" variant="left-accent">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription display="block">{error}</AlertDescription>
            </Box>
          </ChakraAlert>
        )}

        <Stack spacing={4}>
          <Heading>Forgot your password</Heading>
          <FormControl id="email" isRequired>
              <FormLabel>Enter your email address</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon color="gray.300" />}
                />
                <Input
                  type="email"
                  placeholder="Email *"
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.currentTarget.value)
                  }
                />
              </InputGroup>
            </FormControl>
          <Stack spacing={10} align="center">
            <ChakraButton
              variant="outline"
              onClick={handleSubmit}
              type="submit"
            >
              Reset password
            </ChakraButton>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
};
