export enum EditMode {
  Edit = 'Edit',
  Create = 'Create',
}

interface MultiSchedule {
  average_power: number;
  months: number[];
  days: number[];
  hours: number[];
}

export interface ScheduleItem {
  id: string;
  type: string;
  der_name: string;
  user_id: number;
  created_at: Date;
  updated_at: Date;
  data: {
    der_name: string;
    average_power: number;
    start_date: string;
    months: number[];
    days: number[];
    hours: number[];
    schedules?: MultiSchedule[];
  };
}

export type KeyMap = Record<number, string>;

export const monthLabels: KeyMap = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const dayLabels: KeyMap = {
  0: 'Mon',
  1: 'Tue',
  2: 'Wed',
  3: 'Thu',
  4: 'Fri',
  5: 'Sat',
  6: 'Sun',
};

export const hourLabels: KeyMap = {
  0: '00:00',
  1: '01:00',
  2: '02:00',
  3: '03:00',
  4: '04:00',
  5: '05:00',
  6: '06:00',
  7: '07:00',
  8: '08:00',
  9: '09:00',
  10: '10:00',
  11: '11:00',
  12: '12:00',
  13: '13:00',
  14: '14:00',
  15: '15:00',
  16: '16:00',
  17: '17:00',
  18: '18:00',
  19: '19:00',
  20: '20:00',
  21: '21:00',
  22: '22:00',
  23: '23:00',
};
