// in src/devices.js
import React, { FC } from 'react';

import {
  ArrayField,
  List,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  EmailField,
  EditButton,
  ShowButton,
  ReferenceField,
} from 'react-admin';

export const ScheduleList: FC = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="der_name" label="DER Name" />
      <ReferenceField
        label="User"
        source="user_id"
        reference="users"
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="data.average_power" label="Average Power" />
      <DateField source="updated_at" label="Updated" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
