import React, {useEffect} from 'react'

import useAuth from '../hooks/useAuth'

interface Props {
  
}

export const Logout = (props: Props) => {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, []);

  return (
    <div>
    </div>
  )
}
