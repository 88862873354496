import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
} from '@chakra-ui/react';

import { createDeviceInfo, getAllUsers, getProfile, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { useForm } from 'react-hook-form';
import { FormInputs } from './types';
import { Link, useHistory } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { sanitizeEmptyValues } from '../../utils/forms';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

export const NewDeviceInfo: FC = () => {
  const history = useHistory();
  const toast = useToast();
  
  const [profile, setProfile] = useState<ProfileData>({
    email: '',
    first_name: '',
    last_name: '',
  });

  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const handleChange = (fieldId: keyof ProfileData, value: any) => {
    setProfile((currentProfile) => {
      const newProfile = { ...currentProfile };
      newProfile[fieldId] = value;

      return newProfile;
    });
  };

  const queryBackend = async () => {
    try {
   
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    // queryBackend();
  }, []);

  const { handleSubmit } = useForm<FormInputs>();

  const onSubmit = async (data) => {
    console.log(data);
    // Your form submission logic here
    try {
      const response = await createDeviceInfo(sanitizeEmptyValues(data))
      console.log('CREATE RESPONSE', response)
      history.push(`/device-info/${response.id}`)
      
    } catch (error) {
      toast({
        position: 'top',
        isClosable: true,
        status: 'error',
        title: 'Unable to create device',
        description: 'This is an error.',
      });
      setError(error);
    }
  };

  return (
    <>
      <Heading>Device Info page</Heading>
      
      <ButtonLink href="/device-info" title="back to list" />
      <DeviceInfoForm onSubmit={onSubmit} />
    </>
  );
};
