import React, { useState, useEffect } from 'react';

import {
  Box,
  Heading,
  Text,
  Code,
  Button,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
} from '@chakra-ui/react';
import {
  getMyPlants,
  getLinkyMeterConsumption,
  getMyIntegrations,
  getLinkyMeterProduction,
} from '../../utils/api';

import { IntegrationsListItem } from '../IntegrationsList/IntegrationsList';

interface Props {}

export interface UserPlantItem {
  meta: any;
  results: any;
}

export const LinkyPlants = (props: Props) => {
  const [integrationResults, setIntegrationsResults] =
    useState<UserPlantItem | undefined>();
  const [integrations, setIntegrations] =
    useState<IntegrationsListItem[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [intError, setIntError] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>();

  const queryBackend = async (linkyMeter: string | undefined, type: string = "consumption") => {
    try {
      setLoading(true);
      setIntegrationsResults(undefined);
      const myIntegrations = type === "consumption" ? await getLinkyMeterConsumption(linkyMeter) : await getLinkyMeterProduction(linkyMeter);
      

      if (myIntegrations.detail === undefined && error !== "") {
        setError("")
      }
      if (myIntegrations.detail !== undefined) {
        // setIntegrationsResults(undefined)
        setError(myIntegrations.detail);
        throw myIntegrations.detail
        
      }

      if (myIntegrations.results && myIntegrations.results?.error){
        // setIntegrationsResults(undefined)
        setError(myIntegrations.results);
        throw myIntegrations.results
        
      }

      setIntegrationsResults(myIntegrations);


    } catch (err) {
      console.log('This is the error', err);
      setError(err);
    }
  };

  const getLinkyIntegration = async () => {
    try {
      const myIntegrations = await getMyIntegrations();
      setIntegrations(myIntegrations.results);
    } catch (err) {
      setIntError(err);
    }
  };

  useEffect(() => {
    getLinkyIntegration();
  }, []);

  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  const chooseLinkyMeter = () => {
    const linkyMeters =
      integrations &&
      integrations.find((x) => x.name === 'linky')?.resource.split(',');
    console.log('Linky meters are....', linkyMeters, integrations);

    return (
      <>
        <Text>LinkyMeter data here</Text>
        <Select placeholder="Choose the meter" onChange={selectChange}>
          {linkyMeters &&
            linkyMeters.map((x) => {
              return (
                <option key={x} value={x}>
                  {x}
                </option>
              );
            })}
        </Select>
      </>
    );
  };

  return (
    <Box paddingTop="4" paddingBottom="4">
      <Heading>Linky / Enedis Meter Readings</Heading>
      <Text>
        This is the Linky Plants section. Clicking the button below will
        retrieve your consumption for the last 7 days for the chosen meter.
      </Text>
      {integrations && chooseLinkyMeter()}
      <Button
        onClick={() => queryBackend(selectedOption)}
        colorScheme="teal"
        size="md"
        marginRight={1}
        disabled={!selectedOption ? true : false}
      >
        Get consumption of meter {selectedOption}
      </Button>
      <Button
        onClick={() => queryBackend(selectedOption, "production")}
        colorScheme="teal"
        size="md"
        disabled={!selectedOption ? true : false}
      >
        Get production of meter {selectedOption}
      </Button>
      {error !== '' && ( <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {JSON.stringify(error, null, 2)}
          </Code>
          </Box>)}
      {integrationResults && error === '' && integrationResults.results?.error === undefined && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {JSON.stringify(error, null, 2)}
            <Stat>
              <StatLabel>
                Meter {integrationResults.results?.meter_reading.usage_point_id}
              </StatLabel>
              <StatNumber>
                {integrationResults.results?.meter_reading.quality}
              </StatNumber>
              <StatHelpText>
                {integrationResults.results?.meter_reading.start} -{' '}
                {integrationResults.results?.meter_reading.end}
              </StatHelpText>
            </Stat>
            <Text>Readings</Text>
            <Table variant="striped" size="sm" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th isNumeric>Value (W)</Th>
                  <Th isNumeric>Interval</Th>
                  <Th isNumeric>Type</Th>
                </Tr>
              </Thead>
              <Tbody>
                {integrationResults.results &&
                  integrationResults.results.meter_reading.interval_reading.map(
                    ({
                      value,
                      date,
                      interval_length,
                      measure_type,
                    }: {
                      value: string;
                      date: string;
                      interval_length: string;
                      measure_type: string;
                    }) => {
                      return (
                        <Tr>
                          <Td>{date}</Td>
                          <Td isNumeric>{value}</Td>
                          <Td>{interval_length}</Td>
                          <Td isNumeric>{measure_type}</Td>
                        </Tr>
                      );
                    }
                  )}
              </Tbody>
            </Table>
          </Code>
        </Box>
      )}

      {!integrationResults && error === '' && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {!loading ? (
              <pre>Click the button above to request your plant list</pre>
            ) : (
              <Spinner size="xl" />
            )}
          </Code>
        </Box>
      )}
    </Box>
  );
};
