// in src/devices.js
import React, { FC } from 'react';

import {
  ArrayField,
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  EditButton,
  ReferenceField,
} from 'react-admin';

export const DeviceList: FC = (props) => (
  <List {...props}>
    <Datagrid>
      <EditButton />
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="name" />
      <TextField source="data_source" />
      <TextField source="measurement_type" />
      <ReferenceField label="User" source="user_id" reference="users">
        <EmailField source="email" />
      </ReferenceField>
      <TextField source="location_id" />
      <ArrayField source="measures">
        <Datagrid>
          <TextField source="name" />
          <TextField source="unit" />
          <TextField source="display_name" />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);
