import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getAllUsers } from '../../utils';
import { FormInputs } from './types';

interface UserItem {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

interface DeviceMetedataInput {
  name: string;
  display_name: string;
  measure_type: string;
  device_id: number;
  id: number;
  created_at: Date;
  updated_at?: Date;
}

const DeviceMetadataForm = ({
  onSubmit,
  create = false,
  initialData = {},
}: {
  initialData?: {};
  create:boolean;
  onSubmit: (data: any) => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DeviceMetedataInput>({ defaultValues: initialData });

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <input type="text" {...register('device_id', { required: true })} placeholder="Device ID" /> */}
        {create === true ? <FormControl>
          <FormLabel htmlFor="name">Display Name (internal mapping)</FormLabel>
          <Input id="name" {...register('name', { required: true })} />
        </FormControl> : <input type="hidden" {...register('name')} />}
        <FormControl>
          <FormLabel htmlFor="display_name">Display Name</FormLabel>
          <Input id="display_name" {...register('display_name')} />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="measure_type">Type</FormLabel>
          <Select id="measure_type" {...register('measure_type')}>
            <option value="">Select type</option>
            <option value="unset">Unset - please select an option</option>
            <option value="unused">Unused - in case this CT clamp is not connected</option>
            <option value="pv">PV</option>
            <option value="ev">EV</option>
            <option value="total_home">Total Home</option>
            <option value="calc">Calculated</option>
            <option value="other">Other</option>
          </Select>
        </FormControl>
        <input type="hidden" {...register('id')} />
        <input type="hidden" {...register('created_at')} />
        
        <input type="hidden" {...register('device_id')} />

        <Button type="submit" colorScheme="blue">
          Save
        </Button>
      </form>
    </Box>
  );
};

export default DeviceMetadataForm;
