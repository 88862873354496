import React, { FC, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { getMessage, getMyIntegrations, postAcceptTos } from '../utils/api';
import { hasAcceptedTos, isAuthenticated } from '../utils/auth';

import { Header } from '../components/Header';
import { IntegrationsList } from '../components/IntegrationsList';
import { LegrandPlants } from '../components/LegrandPlants';

import useAuth from '../hooks/useAuth';
import {
  Center,
  Stack,
  Box,
  HStack,
  Flex,
  Container,
  Text,
  Heading,
  Link,
  Divider,
  useToast,
  List,
  ListItem,
  ListIcon,
  Button,
  Image,
  SimpleGrid
} from '@chakra-ui/react';

import { CheckCircleIcon, CheckIcon } from '@chakra-ui/icons';
import { ButtonLink } from '../components/ButtonLink';
import { LinkyPlants } from '../components/LinkyPlants';
import { NetatmoHomes } from '../components/NetatmoHomes';
import { GrafanaDashboards } from './GrafanaDashboards';

// const useStyles = makeStyles((theme) => ({
//   link: {
//     color: '#61dafb',
//   },
// }));

const logos = [
	{
		src: '/logos/interrface_logo.png',
		alt: 'interrface project',
		href: 'http://www.interrface.eu',
	},
	{
		src: '/logos/eddie_logo.png',
		alt: 'eddie project',
		href: 'https://eddie.energy',
	},
	{
		src: '/logos/onenet.svg',
		alt: 'onenet project',
		href: 'https://onenet-project.eu',
	},
]

export const Home: FC = () => {
  const [acceptedTos, setAcceptedTos] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();
  const toast = useToast();

  // const queryBackend = async () => {
  //   try {
  //     const message = await getMessage();
  //     setMessage(message);
  //   } catch (err) {
  //     setError(err);
  //   }
  // };

  // const getMyIntegrations = async () => {
  //   try {
  //     const message = await getMessage();
  //     setMessage(message);
  //   } catch (err) {
  //     setError(err);
  //   }
  // };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const created = params.get('created');
    const updated = params.get('updated');
    if (created) {
      toast({
        position: 'top',
        isClosable: true,
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Your integration has been created
          </Box>
        ),
      });
    }
    if (updated) {
      toast({
        position: 'top',
        isClosable: true,
        render: () => (
          <Box color="white" p={3} bg="blue.500">
            Your integration has been updated
          </Box>
        ),
      });
    }
    console.log('[HOME] Found these params on the page', params);
  }, []);

  useEffect(() => {
    const hasAcceptedTosVal = hasAcceptedTos();
    console.log('USE EFFECT GETTING TOS', hasAcceptedTosVal);
    setAcceptedTos(hasAcceptedTosVal);
  }, []);

  const persistScheduleCreate = async (): Promise<unknown | void> => {
    try {
      const acceptTosResult = await postAcceptTos();
      console.log('Success sent accept', acceptTosResult);
      // displayToast(true, 'create', 'schedule');
      return acceptTosResult;
    } catch (error: unknown) {
      alert('Ruh-oh it did not work');
      // displayToast(false, 'create', 'schedule', error);
    }
  };

  const handleClickAcceptTos = async (
    _: React.MouseEvent | React.ChangeEvent | undefined,
    key: string
  ) => {
    const result = await persistScheduleCreate();
    if (result !== undefined) {
      setAcceptedTos(true);
    }
  };

  return (
    <>
      {/* <Header autheticated={isAuthenticated()} /> */}

      {user ? (
        <>
          <Container maxW="container.xl">
            {acceptedTos === false && (
              <Box
                marginTop={4}
                marginBottom={4}
                borderBottom="1px"
                borderColor="gray.200"
                padding={4}
                bg="gray.50"
                borderRadius="lg"
              >
                <Heading as="h3" size="md">
                  Digital4Grids Privacy Principles
                </Heading>
                <Text>
                  We thank you for your interest in our Digital4Grids services
                  targeting to provide real-time fact-based insights on your
                  personal energy usage and flexibility. We are aware the
                  management of these data have a direct implication on your
                  privacy protection and we have defined our data management
                  policy anticipating future European Data Act legislation
                  largely aiming to protect consumers.
                </Text>
                <Text mt={1}>
                  Our data acquisition strictly corresponds to our legitimate
                  business objectives to be able to offer you near real-time
                  analytics to monitor your home energy, power & home carbon
                  footprint performance as well as be able to forecast your
                  consumption and estimate your flexibility to modulate your
                  consumption according to different energy and flexibility
                  price incentives. We therefore offer you the following
                  guarantees:
                </Text>

                <List spacing={3} marginTop={2}>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    Our offering is aligned with European Global Data Protection
                    Regulation principles:{' '}
                    <Link
                      color="teal.500"
                      href="https://gdpr-info.eu/"
                      target="_blank"
                    >
                      <Text as="u">gdpr-info.eu</Text>
                    </Link>
                    <Text>
                      {' '}
                      and anticipate future obligations of internet connected
                      devices in homes to share relevant data with citizens to
                      facilitate the reuse of this data for the development of
                      new services (such as the installation of PV on your roof
                      or the possibility to compare tariffs impacts).
                    </Text>
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    Our solution offers the possibility to export your data
                    history upon request using flat .csv or standardized IEC
                    62746-4 based formats, allowing you to share this data with
                    other energy services with your consent.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    Should you wish to cancel your D4G analytical services, you
                    can at any time send a cancellation request by email to{' '}
                    <Link
                      color="teal.500"
                      href="mailto:aline.levaillant@digital4grids.com"
                      target="_blank"
                    >
                      <Text as="u">aline.levaillant@digital4grids.com</Text>
                    </Link>{' '}
                    following which we guarantee to erase all your associated
                    personal data.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    This service is offered free of charge for 12 months as part
                    of the european <Text as="b">OneNet</Text> project and you
                    implicitly accept to share your personal data with Elering
                    and its relevant IT partners to test new flexibility
                    services.
                  </ListItem>
                </List>
                <Text marginTop={2}>
                  For more legal details you can download our D4G{' '}
                  <Link
                    color="teal.500"
                    href="./digital4grids_data_protection_policy.pdf"
                    isExternal
                  >
                    Data Privacy Policy
                  </Link>{' '}
                  or send your questions to{' '}
                  <Link
                    color="teal.500"
                    href="mailto:aline.levaillant@digital4grids.com"
                    target="_blank"
                  >
                    <Text as="u">aline.levaillant@digital4grids.com</Text>
                  </Link>
                </Text>

                <Button
                  onClick={(e) => handleClickAcceptTos(e, '')}
                  marginTop={2}
                  leftIcon={<CheckIcon />}
                  colorScheme="blue"
                  variant="solid"
                  size="sm"
                >
                  Confirm Acceptance
                </Button>
              </Box>
            )}
          </Container>
          <GrafanaDashboards />
        </>
      ) : (
        <>
          <Box bg={'gray.800'} flex={1}>
            {/* <Flex
              flex={1}
              zIndex={0}
              display={{ base: 'none', lg: 'flex' }}
              // backgroundImage="url('/templates/stats-grid-with-image.png')"
              backgroundSize={'cover'}
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              position={'absolute'}
              width={'50%'}
              insetY={0}
              right={0}
            >
              <Flex
                bgGradient={'linear(to-r, gray.800 10%, transparent)'}
                w={'full'}
                h={'full'}
              />
            </Flex> */}
            <Container maxW={'5xl'} zIndex={10}>
              <Stack direction={{ base: 'column', lg: 'row' }}>
                <Stack
                  flex={1}
                  color={'gray.400'}
                  justify={{ lg: 'center' }}
                  py={{ base: 4, md: 20, xl: 30 }}
                >
                  <Box mb={{ base: 8, md: 20 }}>
                    <Text
                      fontFamily={'heading'}
                      fontWeight={700}
                      textTransform={'uppercase'}
                      mb={3}
                      fontSize={'xl'}
                      color={'gray.500'}
                    >
                      Digital4Grids
                    </Text>
                    <Heading
                      color={'white'}
                      mb={5}
                      fontSize={{ base: '3xl', md: '5xl' }}
                    >
                      Consumer App
                    </Heading>
                    <Text fontSize={'xl'} color={'gray.400'} mb={5}>
                    Welcome to our Digital4Grids Home Energy community.
                    </Text>
                    <SimpleGrid columns={2}>
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' mb={{ base: 2, md: 2 }}>
                      <Image src='/d4g_features.png' alt='Community Features' />
                    </Box>
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' mb={{ base: 2, md: 2 }}>
                      <Image src='/energy_communities.png' alt='Energy Community' />
                    </Box>
                    </SimpleGrid>
                    
                    <Text fontSize={'xl'} color={'gray.400'}>
                      Contact{' '}
                      <Link href="mailto:info@digital4grids.com">
                        info@digital4grids.com
                      </Link>
                    </Text>
                  </Box>
                  <Center p={8}>
                    <HStack spacing={8}>
                      <ButtonLink href="/login" title="Login" />
                      <ButtonLink href="/signup" title="Sign Up" />
                    </HStack>
                  </Center>
          <div className="mx-auto mt-8 max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
					<div className="flex flex-wrap justify-center gap-8 rounded-3xl bg-slate-100 py-4 dark:bg-slate-200">
						<Flex 
              flexWrap="wrap" 
              backgroundColor="gray.200" 
              p={4} borderRadius="3xl" 
              justifyContent="center" 
              gridGap="8"
              
              >
							{logos.map(img => (
										<Link
											href={img.href}
                      key={img.href}
											className="flex h-16 w-32 justify-center p-1 grayscale transition hover:grayscale-0 focus:grayscale-0"
                      height={16}
                      width={32}
                      justifyContent="center"
                      display="flex"
                      filter='grayscale(80%)'
                      _hover={{
                        filter: "none"
                      }}
										>
											<Image
												alt={img.alt}
												src={img.src}
												className="object-contain"
                        objectFit="contain"
											/>
										</Link>
							))}
						</Flex>
					</div>
				</div>
                </Stack>
              </Stack>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};
