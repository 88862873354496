import React, { FC, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  Input,
  Button as ChakraButton,
  Box,
  Heading,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from '@chakra-ui/react';

import { getAllDeviceInfo, getAllUsers, getProfile, updateMyAccount } from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import DeviceInfoForm from './Form';
import { ButtonLink } from '../../components/ButtonLink';
import { FormInputs } from './types';

export interface ProfileData {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}

interface DeviceInfoItem extends FormInputs {
  id: string;
  created_at: Date;
  updated_at: Date;
}

export const DeviceInfo: FC = () => {
  const toast = useToast();
  
  const [profile, setProfile] = useState<ProfileData>({
    email: '',
    first_name: '',
    last_name: '',
  });
  const [devices, setDevices] = useState<DeviceInfoItem[]| null>(null);
  
  const [email, setEmail] = useState<string>('1234@gmail.com');
  const [error, setError] = useState<string>('');
  const { user } = useAuth();

  const handleChange = (fieldId: keyof ProfileData, value: any) => {
    setProfile((currentProfile) => {
      const newProfile = { ...currentProfile };
      newProfile[fieldId] = value;

      return newProfile;
    });
  };

  const queryBackend = async () => {
    try {
      const response = await getAllDeviceInfo()
      setDevices(response)
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    queryBackend();
  }, []);



  return (
    <>
      <Heading>Device Info page</Heading>
      <ButtonLink href="/device-info/new" title="New Device" />
      {/* <DeviceInfoForm /> */}
      {devices?.length && (
        <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Device ID</Th>
            <Th>Device Type</Th>
            <Th>Owner ID</Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {devices.map((device) => (
            <Tr key={device.id}>
              <Td>{device.device_id}</Td>
              <Td>{device.device_type}</Td>
              <Td>{device.owner_id}</Td>
              <Td><ButtonLink href={`/device-info/${device.id}`} title="View" /></Td>
              <Td><ButtonLink href={`/device-info/${device.id}/edit`} title="Edit Device" /></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      ) }
    </>
  );
};
