import React, { FC, useState, useEffect } from 'react';

import { getMyPlants } from '../../utils/api';

import {
  Code,
  Button as ChakraButton,
  Text,
  Heading,
  Box,
  Container,
} from '@chakra-ui/react';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     textTransform: 'none',
//   },
//   margin: {
//     margin: theme.spacing(2),
//   },
//   code: {
//     textAlign: 'left',
//   },
//   codeBlock: {
//     minWidth: '80%',
//     padding: '1rem',
//     backgroundColor: 'rgb(39, 40, 34)',
//   },
// }));

export interface LegrandPlantsProps {}

export interface LegrandPlantsItem {
  data: any;
  meta: any;
  results: any;
  integrations: any;
}

export const LegrandPlants: FC<LegrandPlantsProps> = ({}) => {
  const [integrations, setIntegrations] =
    useState<LegrandPlantsItem | undefined>();
  const [error, setError] = useState<string>('');

  const queryBackend = async () => {
    try {
      const myIntegrations = await getMyPlants();
      setIntegrations(myIntegrations);
    } catch (err) {
      console.log('This is the error', err);
      setError(err);
    }
  };

  return (
    <Box paddingTop="4" paddingBottom="4">
      <Heading as="h1" marginBottom={2}>
        Legrand Plants
      </Heading>
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => queryBackend()}
      >
        Get plants from Home control API
      </Button> */}
      <Text>
        In this section you can test call one of your devices (plants) connected
        via the Legrand IoT system.
      </Text>
      <ChakraButton onClick={() => queryBackend()} colorScheme="teal" size="md">
        Get plants from Home control API
      </ChakraButton>
      {integrations && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            {error}
            {JSON.stringify(integrations.results, null, 2)}
          </Code>
        </Box>
      )}
      {!integrations && (
        <Box bg="rgb(39, 40, 34)" p={5} marginTop={2}>
          <Code>
            <pre>Click the button above to request your plant list</pre>
          </Code>
        </Box>
      )}
    </Box>
  );
};
