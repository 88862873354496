import { EditIcon, AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Wrap,
  Button,
  Divider,
  useToast,
  Text,
  Flex,
  Spacer,
  Badge,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';
import { ScheduleEditor, ScheduleTag } from '../components/Schedules';
import {
  createMySchedule,
  getMySchedules,
  getProfile,
  updateMySchedule,
} from '../utils';
import {
  dayLabels,
  EditMode,
  hourLabels,
  monthLabels,
  ScheduleItem,
} from '../utils/types';
import { ProfileData } from './Protected';

export const Schedules: FC = () => {
  const [profile, setProfile] = useState<ProfileData>({});
  const [schedules, setSchedules] = useState<ScheduleItem[] | undefined>();
  const [error, setError] = useState<unknown>('');
  const [editingScheduleId, setEditingScheduleId] =
    useState<string | undefined>();
  const toast = useToast();
  const [creatingSchedule, setCreatingSchedule] = useState<boolean>(false);

  const queryBackend = async () => {
    try {
      const myIntegrations = await getMySchedules();
      setSchedules(myIntegrations);
    } catch (err: unknown) {
      console.log('This is the error', err);
      setError(err);
    }
  };

  const queryProfile = async () => {
    try {
      const profile = await getProfile();
      setProfile(profile);
    } catch (err) {
      setError(err);
    }
  };

  const displayToast = (
    success: boolean,
    action: string,
    name: string,
    error?: unknown
  ) => {
    toast({
      position: 'bottom',
      isClosable: true,
      render: () => (
        <Box
          color="white"
          p={3}
          bg={success === true ? 'green.500' : 'red.400'}
        >
          {action === 'create' &&
            success === true &&
            `${name} has been created`}
          {action === 'update' &&
            success === true &&
            `${name} has been updated`}
          {action === 'delete' &&
            success === true &&
            `${name} has been deleted`}
          {error !== undefined && `There was an error on ${action}`}
          {error !== undefined && JSON.stringify(error)}
        </Box>
      ),
    });
  };

  const persistScheduleUpdate = async (
    id: string,
    data: ScheduleItem['data']
  ) => {
    try {
      const updateSchedule = await updateMySchedule(id, data);
      console.log('Success updated', updateSchedule);
      displayToast(true, 'update', 'schedule');
    } catch (error: unknown) {
      // alert('Ruh-oh it did not work');
      displayToast(false, 'update', 'schedule', error);
    }
  };

  const persistScheduleCreate = async (
    data: ScheduleItem['data']
  ): Promise<ScheduleItem | void> => {
    try {
      const createSchedule = await createMySchedule(data);
      console.log('Success created', createSchedule);
      displayToast(true, 'create', 'schedule');
      return createSchedule;
    } catch (error: unknown) {
      // alert('Ruh-oh it did not work');
      displayToast(false, 'create', 'schedule', error);
    }
  };

  const handleSave = async (id: string, data: ScheduleItem['data']) => {
    console.log('received modified item', id, data);
    if (schedules) {
      const itemIndex = schedules.findIndex((item) => item.id === id);
      const { [itemIndex]: existingSchedule } = schedules;

      await persistScheduleUpdate(id, data);

      setEditingScheduleId(undefined);

      setSchedules(
        Object.assign([...schedules], {
          [itemIndex]: {
            ...existingSchedule,
            der_name: data.der_name,
            data: data,
          },
        })
      );
    }
  };

  const handleCreate = async (id: string, data: ScheduleItem['data']) => {
    console.log('received create item', id, data);
    if (schedules) {
      const result = await persistScheduleCreate(data);
      if (result !== undefined) {
        setCreatingSchedule(false);
        console.log(result);
        queryBackend();
      }
    }
  };

  useEffect(() => {
    queryProfile();
    queryBackend();
  }, []);

  useEffect(() => {}, [schedules]);

  return (
    <>
      <Box boxShadow="base" p="6">
        <Flex minWidth="max-content" alignItems="center" gap="2">
          <Heading as="h1">Manual Schedules</Heading>
          <Spacer />
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="solid"
            size={'sm'}
            onClick={() => setCreatingSchedule(true)}
          >
            New
          </Button>
        </Flex>
        <div>
          {creatingSchedule && (
            <Box marginBottom={5} paddingTop={5}>
              <>
                <ScheduleEditor
                  id={''}
                  scheduleData={{
                    average_power: 0,
                    der_name: 'new schedule',
                    hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    months: [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12],
                    days: [0, 1, 2, 3, 4, 5, 6],
                    start_date: new Date().toISOString(),
                  }}
                  onSave={handleCreate}
                  cancel={() => setCreatingSchedule(false)}
                  mode={EditMode.Create}
                />
              </>
              <Divider />
            </Box>
          )}
          {!schedules && (
            <Box marginBottom={5} paddingTop={5}>
              <Text>No schedules found. Please create one.</Text>
            </Box>
          )}
          {schedules &&
            schedules.map((item: ScheduleItem) => {
              const { schedules: multiSchedules } = item.data;

              return (
                <>
                  <Box key={item.id} marginBottom={5} paddingTop={5}>
                    {editingScheduleId === item.id && (
                      <>
                        <ScheduleEditor
                          id={item.id}
                          scheduleData={{
                            ...item.data,
                          }}
                          onSave={handleSave}
                          cancel={() => setEditingScheduleId(undefined)}
                          mode={EditMode.Edit}
                        />
                      </>
                    )}

                    <Box
                      display={editingScheduleId === item.id ? 'none' : 'block'}
                    >
                      <Heading as="h3" isTruncated>
                        {item.der_name} ({item.data.average_power}W)
                      </Heading>
                      <Text fontSize="xs">{item.id}</Text>
                      {profile.is_superuser === true &&
                        profile.id !== item.user_id && (
                          <Badge colorScheme="red">
                            IS other UserID: {item.user_id} don't edit
                          </Badge>
                        )}
                      {editingScheduleId !== item.id && (
                        <Button
                          leftIcon={<EditIcon />}
                          colorScheme="teal"
                          variant="outline"
                          size={'sm'}
                          onClick={() => setEditingScheduleId(item.id)}
                          disabled={
                            editingScheduleId !== undefined &&
                            editingScheduleId !== item.id
                          }
                        >
                          Edit
                        </Button>
                      )}
                      {multiSchedules === undefined && (
                        <>
                          <Box p={2}>
                            <Wrap>
                              {Object.keys(monthLabels)
                                .map(Number)
                                .map((month: number) => (
                                  <ScheduleTag
                                    value={month}
                                    compareList={item.data.months}
                                    labels={monthLabels}
                                    clickable={false}
                                  />
                                ))}
                            </Wrap>
                          </Box>
                          <Box p={2}>
                            <Wrap>
                              {Object.keys(dayLabels)
                                .map(Number)
                                .map((day: number) => (
                                  <ScheduleTag
                                    value={day}
                                    compareList={item.data.days}
                                    labels={dayLabels}
                                    clickable={true}
                                  />
                                ))}
                            </Wrap>
                          </Box>
                          <Box p={2}>
                            <Wrap>
                              {Object.keys(hourLabels)
                                .map(Number)
                                .map((hour: number) => (
                                  <ScheduleTag
                                    value={hour}
                                    compareList={item.data.hours}
                                    labels={hourLabels}
                                    clickable={true}
                                  />
                                ))}
                            </Wrap>
                          </Box>
                        </>
                      )}
                      {/* {multiSchedules !== undefined && (
                        <Alert status="info">
                          <AlertIcon />
                          This is a split schedule made of multiple parts.
                        </Alert>
                      )} */}
                      {multiSchedules !== undefined &&
                        multiSchedules.map((sched, index) => (
                          <Box key={index}>
                            Schedule Part ({index})
                            <Box p={2}>
                              <Wrap>
                                {Object.keys(monthLabels)
                                  .map(Number)
                                  .map((month: number) => (
                                    <ScheduleTag
                                      value={month}
                                      compareList={sched.months}
                                      labels={monthLabels}
                                      clickable={false}
                                    />
                                  ))}
                              </Wrap>
                            </Box>
                            <Box p={2}>
                              <Wrap>
                                {Object.keys(dayLabels)
                                  .map(Number)
                                  .map((day: number) => (
                                    <ScheduleTag
                                      value={day}
                                      compareList={sched.days}
                                      labels={dayLabels}
                                      clickable={true}
                                    />
                                  ))}
                              </Wrap>
                            </Box>
                            <Box p={2}>
                              <Wrap>
                                {Object.keys(hourLabels)
                                  .map(Number)
                                  .map((hour: number) => (
                                    <ScheduleTag
                                      value={hour}
                                      compareList={sched.hours}
                                      labels={hourLabels}
                                      clickable={true}
                                    />
                                  ))}
                              </Wrap>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                  <Divider />
                </>
              );
            })}
        </div>
      </Box>
    </>
  );
};
