import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getAllUsers } from '../../utils';


interface UserItem {
  email?: string;
  first_name?: string;
  last_name?: string;
  id?: number;
  is_active?: boolean;
  is_superuser?: boolean;
}


interface FormInputs {
  name: string;
  lat: number;
  user_id: number;
  id?: string;
  country: string;
  lon: number;
}

const LocationForm = ({
  onSubmit,
  initialData = {},
}: {
  initialData?: {};
  onSubmit: (data: any) => void
}) => {

  const [users, setUsers] = useState<UserItem[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: initialData });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const user_response = await getAllUsers();
        setUsers(user_response);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);
  

  
  // const onSubmit = (data) => {
  //   // Handle form submission, either create or update device_info
  //   console.log(data);
  //   handleSubmit(data)
  //   // Replace with your API call to create or update device_info
  // };

  return (
    <Box p={4}>
      {users.length && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <input type="text" {...register('device_id', { required: true })} placeholder="Device ID" /> */}
          <FormControl isInvalid={errors.name ? true : false}>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              {...register('name', { required: true })}
              placeholder="Location Name"
            />
            <FormErrorMessage>Location name is required</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="country">Country</FormLabel>
            <Select id="country" {...register('country')}>
              <option value="">Select country</option>
              <option value="FR">FR</option>
              <option value="DE">DE</option>
              <option value="IE">IE</option>
              <option value="AT">AT</option>
              <option value="EE">EE</option>
              <option value="CA">CA</option>
              <option value="US">US</option>
            </Select>
          </FormControl>

          <FormControl>
                <FormLabel htmlFor="lat">Latitude</FormLabel>
                <Input type="text" id="lat" {...register('lat', { required: true,  pattern: /^-?\d*\.?\d{0,8}$/ })} defaultValue={0.0} />
                {errors.lat && <span>Latitude is required</span>}
              </FormControl>
          <FormControl>
            <FormLabel htmlFor="lon">Longitude</FormLabel>
            <Input type="text" id="lon" {...register('lon', { required: true,  pattern: /^-?\d*\.?\d{0,8}$/ })} defaultValue={0.0} />
            {errors.lon && <span>Longitude is required</span>}
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Submit
          </Button>
        </form>
      )}
    </Box>
  );
};

export default LocationForm;
