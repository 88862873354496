import React, { FC, useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  VStack,
  Button,
  Box,
  Fade,
  Text,
  useToast,
  Select,
  Checkbox,
  Heading,
  Grid,
} from '@chakra-ui/react';
import {
  useForm,
  SubmitHandler,
  UseFormRegister,
  FormState,
  useFieldArray,
  Control,
} from 'react-hook-form';
import { PlusSquareIcon, CloseIcon } from '@chakra-ui/icons';
import { sanitizeEmptyValues } from '../../utils/forms';
import {
  DataSource,
  DeviceType,
  Inputs,
  SmartApplianceCategory,
  UserDevice,
  UserLocation,
} from './types';
import { createUserDevice } from '../../utils';

const DEFAULT_VALUES: Inputs = {
  device_id: 'asfdfsdf',
  integration_id: '1',
  data: {
    source: DataSource.MQTT,
    channels: [
      {
        name: 'asd',
        classification: 'consumption',
        prefix: '',
        metadata: {
          is: {
            programmable: false,
          },
          // "smartApplianceCategory": "",
          measurements: [],
        },
        suffix: '',
      },
      {
        name: 'asd',
        classification: 'consumption',
        prefix: '',
        metadata: {
          is: {
            programmable: false,
          },
          // "smartApplianceCategory": "",
          measurements: [],
        },
        suffix: '',
      },
    ],
  },
  device_type: 'legrand',
  location_id: '1234',
};

const ChannelFormItem = ({
  number,
  register,
  errors,
  control,
}: {
  number: number;
  register: UseFormRegister<Inputs>;
  errors: FormState<Inputs>['errors'];
  control: Control<Inputs>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `data.channels.${number}.metadata.measurements`,
  });

  return (
    <Box key={number} paddingBottom={5} paddingTop={10} borderBottom={'2px'} marginBottom={2}>
      <Box paddingBottom={5}>
        <Heading size={'md'}>Channel {number + 1}</Heading>
      </Box>
      <FormControl>
        <FormLabel htmlFor={`data.channels.${number}.name`}>
          Channel Name
        </FormLabel>
        <Input
          {...register(`data.channels.${number}.name`, {
            required: {
              value: true,
              message: 'Channel name required',
            },
          })}
          id={`data.channels.${number}.name`}
          type="text"
          placeholder="Channel Name..."
        />
        <FormHelperText>Display name in graphs.</FormHelperText>
        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.name && (
            <FormErrorMessage>
              {errors.data?.channels[number]?.name?.message}
            </FormErrorMessage>
          )}
      </FormControl>

      <FormControl>
        <FormLabel htmlFor={`data.channels${number}.classification`}>
          Classification
        </FormLabel>
        <Select
          placeholder="Select classification"
          id={`data.channels${number}.classification`}
          {...register(`data.channels.${number}.classification`, {
            required: {
              value: true,
              message: 'Channel classification required',
            },
          })}
        >
          <option value={'consumption'}>Consumption</option>
          <option value={'production'}>Production</option>
          <option value={'channel_classification_unknown'}>Unknown</option>
        </Select>
        <FormHelperText>Should we treat values as a negative.</FormHelperText>
        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.classification && (
            <FormErrorMessage>
              {errors.data.channels[number]?.classification?.message}
            </FormErrorMessage>
          )}
      </FormControl>
      <FormControl>
        <FormLabel htmlFor={`data.channels${number}.prefix`}>prefix</FormLabel>
        <Input
          {...register(`data.channels.${number}.prefix`, {
            required: {
              value: false,
              message: 'Channel prefix required',
            },
          })}
          id={`data.channels${number}.prefix`}
          type="text"
          placeholder="Channel prefix..."
        />
        <FormHelperText>Used to construct the database field name in combination with the measurements eg <Text as='b' fontWeight={'bold'}>A</Text>WATTHR.</FormHelperText>
        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.prefix && (
            <FormErrorMessage>
              {errors.data.channels[number]?.prefix?.message}
            </FormErrorMessage>
          )}
      </FormControl>
      <FormControl>
        
        <Checkbox
          {...register(`data.channels.${number}.metadata.is.programmable`, {
            required: {
              value: false,
              message: 'Channel .metadata.is.programmable required',
            },
          })}
          id={`data.channels${number}.metadata.is.programmable`}
        >
          Channel is programmable
        </Checkbox>
        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.metadata?.is?.programmable && (
            <FormErrorMessage>
              {
                errors.data.channels[number]?.metadata?.is?.programmable
                  ?.message
              }
            </FormErrorMessage>
          )}
      </FormControl>
      <FormControl>
        <FormLabel htmlFor={`data.channels${number}.suffix`}>suffix</FormLabel>
        <Input
          {...register(`data.channels.${number}.suffix`, {
            required: {
              value: false,
              message: 'Channel suffix required',
            },
          })}
          id={`data.channels${number}.suffix`}
          type="text"
          placeholder="Channel suffix..."
        />
        <FormHelperText>Used to construct the database field name in combination with the measurements eg WATTHR<Text as='b' fontWeight={'bold'}>A</Text>.</FormHelperText>
        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.suffix && (
            <FormErrorMessage>
              {errors.data.channels[number]?.suffix?.message}
            </FormErrorMessage>
          )}
      </FormControl>
      
      <Box paddingBottom={5} paddingTop={5} marginTop={5} borderTop={'1px'} borderColor={'gray.400'}>
        <Heading size={'sm'}>Metadata</Heading>
      </Box>
      <FormControl>
        <FormLabel
          htmlFor={`data.channels.${number}.metadata.smartApplianceCategory`}
        >
          Smart Application Category
        </FormLabel>

        <Select
          placeholder="Select classification"
          id={`data.channels.${number}.metadata.smartApplianceCategory`}
          {...register(
            `data.channels.${number}.metadata.smartApplianceCategory`,
            {
              required: {
                value: false,
                message: 'Channel smartApplianceCategory is optional.',
              },
            }
          )}
        >
          <option
            value={
              SmartApplianceCategory.BATTERY_OPERATED_RECHARGEABLE_APPLIANCES
            }
          >
            {SmartApplianceCategory.BATTERY_OPERATED_RECHARGEABLE_APPLIANCES}
          </option>
          <option value={SmartApplianceCategory.BEHAVIORAL_APPLIANCES}>
            {SmartApplianceCategory.BEHAVIORAL_APPLIANCES}
          </option>
          <option value={SmartApplianceCategory.CONTINUOUS_APPLIANCES}>
            {SmartApplianceCategory.CONTINUOUS_APPLIANCES}
          </option>
          <option value={SmartApplianceCategory.HVAC}>
            {SmartApplianceCategory.HVAC}
          </option>
          <option value={SmartApplianceCategory.PERIODICAL_APPLIANCES}>
            {SmartApplianceCategory.PERIODICAL_APPLIANCES}
          </option>
          <option
            value={SmartApplianceCategory.RESIDENTIAL_ENERGY_STORAGE_SYSTEM}
          >
            {SmartApplianceCategory.RESIDENTIAL_ENERGY_STORAGE_SYSTEM}
          </option>
        </Select>

        {errors.data?.channels !== undefined &&
          errors.data?.channels[number]?.metadata?.smartApplianceCategory && (
            <FormErrorMessage>
              {
                errors.data.channels[number]?.metadata?.smartApplianceCategory
                  ?.message
              }
            </FormErrorMessage>
          )}
      </FormControl>

      <FormControl marginTop={5}>
        <Text fontSize={'2xl'} fontWeight={'medium'}>Measurements taken on this device</Text>
        <Grid templateColumns='repeat(3, 1fr)' gap={6}>
        {fields.map(({ id }, index) => (
          <Box id={id} key={id} marginBottom={5}>
            <Text fontSize={'lg'} fontWeight={'medium'}>{index+1}</Text>
            <FormControl>
              <FormLabel
                htmlFor={`data.channels.${number}.metadata.measurements.${index}.unit`}
              >
                Unit
              </FormLabel>
              <Input
                {...register(
                  `data.channels.${number}.metadata.measurements.${index}.unit`,
                  {
                    required: {
                      value: false,
                      message: 'Unit not required',
                    },
                  }
                )}
                id={`data.channels.${number}.metadata.measurements.${index}.unit`}
                type="text"
                placeholder="Measurement unit..."
              />
              {/* {errors?.data?.channels[number]?.metadata?.measurements[index]?.unit !== undefined &&
                    errors?.data?.channels[number]?.metadata?.measurements[index]?.unit && (
                      <FormErrorMessage>
                        {errors?.data?.channels[number]?.metadata?.measurements[index]?.unit?.message}
                      </FormErrorMessage>
                    )} */}
            </FormControl>
            <FormControl>
              <FormLabel
                htmlFor={`data.channels.${number}.metadata.measurements.${index}.databaseMeasurementPrefix`}
              >
                Database Prefix
              </FormLabel>
              <Input
                {...register(
                  `data.channels.${number}.metadata.measurements.${index}.databaseMeasurementPrefix`,
                  {
                    required: {
                      value: false,
                      message: 'databaseMeasurementPrefix not required',
                    },
                  }
                )}
                id={`data.channels.${number}.metadata.measurements.${index}.databaseMeasurementPrefix`}
                type="text"
                placeholder="Measurement databaseMeasurementPrefix..."
              />
              {/* {errors?.data?.channels[number]?.metadata?.measurements[index]?.databaseMeasurementPrefix !== undefined &&
                    errors?.data?.channels[number]?.metadata?.measurements[index]?.databaseMeasurementPrefix && (
                      <FormErrorMessage>
                        {errors?.data?.channels[number]?.metadata?.measurements[index]?.databaseMeasurementPrefix?.message}
                      </FormErrorMessage>
                    )} */}
            </FormControl>
            <Button
              leftIcon={<CloseIcon />}
              colorScheme="red"
              variant={'outline'}
              size={'sm'}
              onClick={() => remove(index)}
            >
              Remove Measurement
            </Button>
          </Box>
        ))}
        </Grid>
        <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="blue"
          variant={'outline'}
          size={'sm'}
          onClick={() => append({ unit: '', databaseMeasurementPrefix: '' })}
        >
          Add Measurement
        </Button>
      </FormControl>
    </Box>
  );
};

const Form: FC<{ selectedUserDevice: UserDevice | null; userLocations: UserLocation[]}> = ({
  selectedUserDevice,
  userLocations
}) => {
  const toast = useToast();
  const [result, setResult] = useState<string>('');
  const [error, setError] = useState<string>('');

  const submitForm = async (userDevice: Inputs) => {
    setError('');
    try {
      const data = await createUserDevice(userDevice);

      if (data) {
        toast({
          position: 'top',
          isClosable: true,
          status: 'success',
          title: 'Device created',
          description: 'You created a new device.',
        });
      }
    } catch (err) {
      console.log('Ruho error', err);
      setError(err);
      if (err instanceof Error) {
        // handle errors thrown from frontend
        setError(err.message);
      } else {
        // handle errors thrown from backend
        setError(err as string);
      }
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: `data.channels`,
  });

  useEffect(() => {
    if (selectedUserDevice !== null) {
      reset(selectedUserDevice);
    }
  }, [selectedUserDevice]);

  const onSubmit: SubmitHandler<Inputs> = async (values) => {
    toast.closeAll();
    console.log(values.data.channels);
    console.log('sanitise', sanitizeEmptyValues(values));
    console.log('sanitise', JSON.stringify(sanitizeEmptyValues(values)));
    await submitForm(sanitizeEmptyValues(values));
  };

  return (
    <Box as="main" maxW="container.lg" textAlign={'left'}>
      {/* <Container maxW='container.sm'> */}

      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={5} border="1px solid #DDD" p={7} rounded="lg">
          {error.length > 0 && (
            <Box
              borderWidth="2px"
              borderRadius="lg"
              borderColor={'red.500'}
              p={4}
            >
              <Text color={'red.500'} fontWeight={'bold'}>
                There was an error:
              </Text>
              <Text color={'red.500'}> {error}</Text>
            </Box>
          )}
          {result && (
            <Box>
              <Fade in>
                <Text>{result} ✅</Text>
              </Fade>
            </Box>
          )}
          <FormControl isInvalid={!!errors.device_id}>
            <FormLabel htmlFor="name">Device ID</FormLabel>
            <Input
              {...register('device_id', {
                required: {
                  value: true,
                  message: 'Device ID is required fill',
                },
                pattern: {
                  value: /^[a-zA-Z ]*$/,
                  message: 'Name only alphabet',
                },
              })}
              id="device_id"
              type="text"
              placeholder="Input your name..."
            />
            {errors.device_id && (
              <FormErrorMessage>{errors.device_id.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.device_type}>
            <FormLabel htmlFor="device_type">device_type</FormLabel>
            <Select
              placeholder="Select data device_type"
              id={`device_type`}
              {...register(`device_type`, {
                required: {
                  value: true,
                  message: 'device_type required',
                },
              })}
            >
              <option value={DeviceType.LEGRAND}>Legrand API</option>
              <option value={DeviceType.LINKY}>Linky API</option>
              <option value={DeviceType.NETATMO}>Netatmo API</option>
              <option value={DeviceType.SHELLY}>Shelly</option>
              <option value={DeviceType.SPOONY}>Spoony</option>
              <option value={DeviceType.DEVICE_TYPE_UNKNOWN}>Unknown</option>
            </Select>
            {errors.device_type && (
              <FormErrorMessage>
                {errors?.device_type?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.location_id}>
            <FormLabel htmlFor="location_id">Location</FormLabel>
            <Select
              placeholder="Select data location_id"
              id={`location_id`}
              {...register(`location_id`, {
                required: {
                  value: true,
                  message: 'location required',
                },
              })}
            >
              {userLocations.length && userLocations.map((location: UserLocation) => (<option key={location.id} value={location.id}>
                {location.name} ({location.country})
              </option>))}
              
            </Select>
            {errors.location_id && (
              <FormErrorMessage>
                {errors?.location_id?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.integration_id}>
            <FormLabel htmlFor="age">Integration</FormLabel>
            <Input
              {...register('integration_id', {
                required: {
                  value: true,
                  message: 'Integration is required fill',
                },
              })}
              id="integration_id"
              type="text"
              placeholder="Chose an integration..."
            />
            {errors.integration_id && (
              <FormErrorMessage>
                {errors.integration_id.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.data?.source}>
            <FormLabel htmlFor="data.source">Source</FormLabel>
            <Select
              placeholder="Select data source"
              id={`data.source`}
              {...register(`data.source`, {
                required: {
                  value: true,
                  message: 'Source required',
                },
              })}
            >
              <option value={DataSource.LEGRAND_API}>Legrand API</option>
              <option value={DataSource.LINKY_API}>Linky / Enedis</option>
              <option value={DataSource.MQTT}>MQTT</option>
              <option value={DataSource.UNKNOWN_DATA_SOURCE}>Unknown</option>
            </Select>
            {errors.data?.source && (
              <FormErrorMessage>{errors.data?.source.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isInvalid={!!errors.data?.mqtt?.host}>
            <FormLabel htmlFor="data.mqtt.host">MQTT Host</FormLabel>
            <Input
              {...register('data.mqtt.host', {
                required: {
                  value: false,
                  message: 'MQTT Host is required fill',
                },
              })}
              id="data.mqtt.host"
              type="text"
              placeholder="Data Source..."
            />
            {errors.data?.mqtt?.host && (
              <FormErrorMessage>
                {errors.data.mqtt.host.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <Heading size={'xl'}>Device Channels</Heading>
          <Box w="full">
            {/* name: string;
  classification: string;
  prefix: string; */}
   {fields.map(({ id }, index) => (<ChannelFormItem
              key={id}
              number={index}
              register={register}
              errors={errors}
              control={control}
            />))
          }
          <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="blue"
          variant={'outline'}
          size={'sm'}
          onClick={() => append({name: '',
            classification: ''})}
        >
          Add Channel
        </Button>
            {/* <ChannelFormItem
              number={0}
              register={register}
              errors={errors}
              control={control}
            />
            <ChannelFormItem
              number={1}
              register={register}
              errors={errors}
              control={control}
            /> */}
          </Box>

          <Button
            // isLoading={mutation.isLoading}
            loadingText="Submitting"
            colorScheme="blue"
            w="full"
            type="submit"
          >
            Save
          </Button>
        </VStack>
      </form>
      {/* </Container> */}
    </Box>
  );
};

export default Form;
